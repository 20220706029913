<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-left">
          <b-card-title>Create Your Digital B2B Store</b-card-title>
          <b-card-text>
            Build and customize your digital store with our powerful tools.
            You can offer services like derivatives or appointment scheduling with ease.
            <br><br>
            <p><b>Requirements:</b></p>
            <ul>
              <li><b>Token BCA:</b> Essential for platform operations.</li>
              <li><b>Token BNB:</b> Required for transaction fees on Binance Smart Chain.</li>
            </ul>
            <p><b>Blockchain:</b> Binance Smart Chain.</p>
            <p>
              To top up your balance, visit the Wallet section and
              <b-link :to="{name:'wallet', params: { iz: false}}">
                <span style="color: #75c469;">Make a Deposit</span>
              </b-link>.
              Use the QR code to deposit both tokens. If you only have BNB, you can exchange it for BCA using Bancannabis' P2P market.
            </p>
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="cursor:pointer; float:right"
            @click="deploy">
            <feather-icon icon="PlusIcon" size="16" /> Create Store
          </b-button>
        </b-card>

      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle, BButton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: [
    'deployevent',
  ],
  data() {
    return {
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
      blockchains: [
        // {
        //   title: 'GANACHE Mainnet',
        //   icon: 'BoxIcon',
        //   id: 5777,
        //   name: 'GANACHE',
        //   provider: 'HTTP://127.0.0.1:7545',
        //   scanner: '',
        //   symbol: 'BNB',
        // },
        {
          title: 'Binance (BSC) Mainnet',
          icon: 'BoxIcon',
          id: 0x38,
          name: 'Binance Coin',
          provider: 'https://bsc-dataseed.binance.org/',
          scanner: 'https://bscscan.com',
          symbol: 'BNB',
        },
        // {
        //   title: 'Binance Smart Chain (TESTNET)',
        //   icon: 'BoxIcon',
        //   id: 0x61,
        //   name: 'Binance Coin',
        //   provider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'CELO Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0xA4EC,
        //   name: 'CELO',
        //   provider: 'https://celo-mainnet.infura.io',
        //   scanner: 'https://celoscan.io',
        //   symbol: 'CELO',
        // },
      ],
    }
  },
  methods: {
    deploy() {
      // debugger
      this.$props.deployevent(this.blockchainId.id)
    },
  },
}
</script>
