<template>
  <div>
    <form-wizard
      ref="wizardCompany"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      back-button-text="Back"
      finish-button-text="Upload Documents"
      class="wizard-vertical mb-3"
    >
      <tab-content
        title="Company Info."
        :before-change="validateStep1"
      >
        <b-row>
          <b-col
            cols="8"
            class="mb-2"
          >
            <h5 class="mb-0">
              Company Info
            </h5>
            <small class="text-muted">Enter Your Company Info.</small>
          </b-col>
          <b-col
            cols="4"
          >
            <b-img
              v-show="urlLogo"
              :src="urlLogo"
              fluid
              width="180px"
              style="background: white;"
              alt="Company Image"
            />
          </b-col>
        </b-row>

        <validation-observer ref="companyFormValidation">
          <b-form>
            <b-row>
              <!--  This field is required-->
              <b-col md="6">
                <b-form-group>
                  <label>Company Name *</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Company Name"
                  >
                    <b-form-input
                      v-model="name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Company Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--  This field is required-->
              <b-col md="6">
                <b-form-group>
                  <label>Description *</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Description"
                  >
                    <b-form-input
                      v-model="description"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Must be a valid email -->
              <b-col md="6">
                <b-form-group>
                  <label>Contact e-Mail *</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="usremail"
                      :disabled="disabledMail"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Contact e-mail"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Must match the specified regular expression : ^([0-9]+)$ - numbers only -->
              <b-col md="6">
                <b-form-group>
                  <label>Phone * | numbers only</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|regex:^([0-9]+)$|min:10"
                    name="Phone"
                  >
                    <b-form-input
                      v-model="phone"
                      :state="errors.length > 0 ? false:null"
                      placeholder="3010001122"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label>Address *</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="address"
                  >
                    <b-form-input
                      v-model="address"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Add your prinipal address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label>Address 2</label>
                  <validation-provider
                    #default="{ errors }"
                    rules=""
                    name="address 2"
                  >
                    <b-form-input
                      v-model="address2"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Add your secundary address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Country"
                  label-for="v-country"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Country"
                  >
                    <v-select
                      id="v-country"
                      v-model="country"
                      :options="thecountries"
                      placeholder="Select a country"
                      name="country"
                      label="text"
                      @input="onChangeCountry"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="State"
                  label-for="v-state"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="State"
                  >
                    <v-select
                      id="v-state"
                      v-model="estate"
                      :options="filterStates"
                      label="text"
                      @input="onChangeState"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="City"
                  label-for="v-city"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="City"
                  >
                    <v-select
                      v-model="city"
                      label="text"
                      :options="filterCities"
                      placeholder="Select the City"
                      name="city"
                      class="w-full mb-1"
                      style="margin-top: 17px;"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- The digits field must be numeric and exactly contain 3 digits -->
              <b-col md="6">
                <b-form-group>
                  <label>ZIP Code *</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="ZIP Code"
                  >
                    <b-form-input
                      v-model="zipcode"
                      :state="errors.length > 0 ? false:null"
                      placeholder="ZIP Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
      <!-- upload docs company tab -->
      <tab-content
        title="Add Documents"
        :before-change="validateStep2"
      >
        <b-row>
          <b-col>
            <p class="text-lg mb-2">
              Attach the documents of the Company for verification
            </p>
            <div
              class="con-input-upload"
            >
              <span class="text-input"> Company Logo (612x218) or higher, Max 10MB </span>
              <br>
              <b-img
                v-show="urlLogo"
                :src="urlLogo"
                fluid
                width="200px"
                alt="Company Image"
              />
              <form
                ref="form3"
                method="post"
                target="ifmImage3"
                enctype="multipart/form-data"
                style="z-index: 100;"
              >
                <div class="file-upload-wrapper">
                  <b-form-file
                    ref="file-input3"
                    class="mb-2"
                    name="image1"
                    @change="selectImg1($event)"
                  />
                </div>
                <iframe
                  id="ifmImage3"
                  name="ifmImage3"
                  src=""
                  style="border: 0px; display: none;"
                />
              </form>
            </div>
            <!-- disabled-upload -->
            <div
              class="con-input-upload"
            >
              <span class="text-input"> Camara de Comercio (PDF) </span>
              <form
                ref="form1"
                method="post"
                target="ifmImage1"
                enctype="multipart/form-data"
                style="z-index: 100;"
              >
                <div class="file-upload-wrapper">
                  <b-form-file
                    ref="file-input1"
                    class="mb-2"
                    name="image1"
                    @change="selectImg1($event)"
                  />
                </div>
                <iframe
                  id="ifmImage1"
                  name="ifmImage1"
                  src=""
                  style="border: 0px; display: none;"
                />
              </form>
            </div>
            <!-- disabled-upload -->
            <div
              class="con-input-upload"
            >
              <span class="text-input"> RUT (PDF) </span>
              <form
                ref="form2"
                method="post"
                target="ifmImage2"
                enctype="multipart/form-data"
                style="z-index: 100;"
              >
                <div class="file-upload-wrapper">
                  <b-form-file
                    id="image2"
                    ref="file-input2"
                    class="mb-2"
                    name="image1"
                    @change="selectImg2($event)"
                  />
                </div>
                <iframe
                  id="ifmImage2"
                  name="ifmImage2"
                  src=""
                  style="border: 0px; display: none;"
                />
              </form>
            </div>
            <p class="text-sm">
              Permitido JPG, JPEG ó PNG. Max tamaño of 1MB
            </p>
            <b-modal
              ref="modalLoading"
              centered
              ok-only
              hide-footer
              ok-disabled
              ok-title="Accept"
              no-close-on-backdrop
              title="Uploading, wait please..."
            >
              <b-col>
                <b-progress
                  animated
                  centered
                  :value="valueProgress"
                  variant="success"
                  class="'progress-bar-success'"
                />
                <span>Uploading documents, please don't close this window...</span>
              </b-col>
            </b-modal>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BProgress,
  BModal,
  VBModal,
  BImg,
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import states from '../../../assets/json/states.json'
import countries from '../../../assets/json/countries.json'
import cities from '../../../assets/json/cities.json'

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormFile,
    BForm,
    BProgress,
    BModal,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: [
    'companystange',
  ],
  data() {
    return {
      // variables que manejan el flujo del company
      userData: null,
      img1Selected: false,
      successImg1: false,
      img2Selected: false,
      img1Base64: '',
      successImg2: false,
      disabledImg1: false,
      disabledImg2: false,
      companyVerifing: false,
      companyVerifingOk: false,
      companyDocumentsLoad: false,
      uploadingDocs: false,
      disabledMail: 'false',

      // modelo
      name: '',
      description: '',
      usremail: '',
      phone: '',
      address: '',
      address2: '',
      city: '',
      country: '',
      estate: '',
      filterStates: [],
      filterCities: [],
      zipcode: '',
      companyId: null,
      img1Action: '',
      img2Action: '',
      img3Action: '',
      uploadImg1State: false,
      uploadImg2State: false,
      uploadImg3State: false,
      urlLogo: '',

      // Upload image progress
      valueProgress: 10,

      // listado para seleccionar
      thestates: states,
      thecountries: countries,
      thecities: cities,

      // Validaciones
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
    }
  },
  created() {
    // informacion del usuario
    this.userData = this.$sessionData.get()
    this.usremail = this.userData.data.invitation.email
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
    this.getCompanyByWallet()
    /*
      if (this.usremail.includes('@yopmail.com')) {
        this.disabledMail = false
        this.usremail = ''
      }
    */
    // variable para manejar el contexto dentro del setTimeOut
    const that = this
    // si ya hay un kyc
    // if (that.userData.kyc !== '' && (that.userData.data.kyc.name !== '' && that.userData.data.kyc.sourceDocument1)) {
    //   this.toHome()
    // }
    // if (that.userData.metamaskSigin) {
    this.disabledMail = true
    // }
    // Verificar el estado del company y el formulario a llenar
    setTimeout(() => {
      // estado 0 = tab1
      if (this.companyId !== null) {
        // tab de adjuntar documentos
        that.$refs.wizardCompany.activateTabAndCheckStep(0)
        // quitar evento volver
        that.$refs.wizardCompany.prevTab = () => {}
        // setear informacion del company
        // that.companyId = that.userData.data.company.id
      }
    }, 500)
  },
  methods: {
    getCompanyByWallet() {
      this.$http.get(`/company/${this.usremail}/email?nid=${this.blockchainId.id}`)
        .then(response => {
          this.name = response.data.company.name
          this.description = response.data.company.lastName
          this.usremail = response.data.company.email
          this.phone = response.data.company.phone
          this.address = response.data.company.address1
          this.address2 = response.data.company.address2
          this.city = response.data.company.city
          this.country = response.data.company.country
          this.estate = response.data.company.state
          this.zipcode = response.data.company.zipCode
          this.companyId = response.data.company.id
          this.uploadImg1State = response.data.company.sourceDocument1 !== '' ? 1 : 0
          this.uploadImg2State = response.data.company.sourceDocument2 !== '' ? 1 : 0
          this.uploadImg3State = response.data.company.sourceDocument3 !== '' ? 1 : 0
          if (this.uploadImg3State) {
            this.urlLogo = `https://e-groweed.com:3800/api/v1/image/${response.data.company.sourceDocument3}`
          }

          if (this.uploadImg1State) {
            this.$refs['file-input1'].disabled = 'disabled'
          }
          if (this.uploadImg2State) {
            this.$refs['file-input2'].disabled = 'disabled'
          }
          if (this.uploadImg3State) {
            this.$refs['file-input3'].disabled = 'disabled'
          }
        })
    },
    showModal() {
      this.uploadingDocs = true
      this.$refs.modalLoading.show()
    },
    closeModal() {
      this.uploadingDocs = false
      this.$refs.modalLoading.hide()
      // this.toHome()
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    // Cargar combos
    onChangeCountry(e) {
      this.estate = ''
      if (e) this.filterStates = this.thestates.filter(state => state.id_country === e.value)
      else {
        this.filterStates = []
        this.city = ''
        this.estate = ''
        this.filterCities = []
      }
    },
    onChangeState(e) {
      this.city = ''
      if (e) this.filterCities = this.thecities.filter(city => city.id_state === e.value)
      else {
        this.filterCities = []
        this.city = ''
      }
    },
    uploadImage3() {
      return new Promise(resolve => {
        if (this.successImg3) {
          resolve()
          return
        }
        this.valueProgress += 20
        this.$refs.form3.action = `https://e-groweed.com:3800/api/v1/company/${this.companyId}/document3`
        this.$refs.form3.submit()
        this.successImg3 = true
        setTimeout(() => {
          this.img3Success() // cambiar estado a enviado
          resolve()
        }, 20000)
      })
    },
    uploadImage1() {
      return new Promise(resolve => {
        if (this.successImg1) {
          resolve()
          return
        }
        this.valueProgress += 20
        this.$refs.form1.action = `https://e-groweed.com:3800/api/v1/company/${this.companyId}/document`
        this.$refs.form1.submit()
        this.successImg1 = true
        setTimeout(() => {
          this.img1Success() // cambiar estado a enviado
          resolve()
        }, 20000)
      })
    },
    clearImg1() {
      this.img1Base64 = ''
      this.img1Selected = false
    },
    selectImg1(e) {
      if (!this.disabledImg1) {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
          this.img1Base64 = reader.result
          this.img1Selected = true
        }
      }
    },
    // Validaciones y eventos de Imagen 2
    uploadImage2() {
      return new Promise(resolve => {
        if (this.successImg2) {
          resolve()
          return
        }

        this.valueProgress += 20
        this.$refs.form2.action = `https://e-groweed.com:3800/api/v1/company/${this.companyId}/document2`
        this.$refs.form2.submit()
        this.successImg2 = true
        setTimeout(() => {
          this.img2Success()
          resolve()
        }, 20000)
      })
    },
    clearImg2() {
      this.img2Base64 = ''
      this.img2Selected = false
    },
    selectImg2(e) {
      if (!this.disabledImg2) {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
          this.img2Base64 = reader.result
          this.img2Selected = true
        }
      }
    },
    clearImg3() {
      this.img3Base64 = ''
      this.img3Selected = false
    },
    selectImg3(e) {
      if (!this.disabledImg3) {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
          this.img3Base64 = reader.result
          this.img3Selected = true
        }
      }
    },
    validateStep1() {
      return new Promise(resolve => {
        try {
          this.$refs.companyFormValidation.validate().then(result => {
            // Si ya se realizó el company
            if (this.companyId !== null) {
              resolve(true)
              return
            }
            if (result) {
              // guardar company
              this.$http.defaults.headers['x-access-token'] = this.userData.token
              this.$http.post(`/company?nid=${this.blockchainId.id}`, {
                name: this.name,
                lastName: this.description,
                email: this.usremail,
                phone: this.phone,
                address1: this.address,
                address2: this.address2,
                city: this.city.text,
                country: this.country.text,
                state: this.estate.text,
                zipCode: this.zipcode,
                wallet: this.userData.data.invitation.wallet,
                agreePolics: true,
              })
                .then(async result1 => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'The data has been saved successfully! Please, it is necessary that you attach require document to verify your company.',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 15000,
                  })

                  this.companyId = result1.data.id
                  // Obtener el detalle del company
                  // this.userData.company = this.companyId
                  // this.$http.defaults.headers['hey!'] = 'jg3*'
                  // const companyData = await this.$http.get(`/company/${this.companyId}`)
                  // this.userData.data.company = companyData.data.company
                  // Guardamos los datos en sesion
                  // this.$sessionData.set(this.userData)
                  // Cambiar la url de los formularios que subiran las imagenes al servidor
                  this.img1Action = `https://e-groweed.com:3800/company/${this.companyId}/document`
                  this.img2Action = `https://e-groweed.com:3800/company/${this.companyId}/document2`
                  // si se guardo correctamente pasamos al siguiente paso
                  resolve(true)
                })
                .catch(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'We are sorry :(',
                      icon: 'CloseIcon',
                      variant: 'warning',
                      text: 'Failed to save data, please check data and try again.',
                    },
                  })
                  resolve(false)
                })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'We are sorry :(',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: 'Please fill out the Company form to continue.',
                },
              })
              resolve(false)
            }
          })
        } catch (err) {
          resolve(false)
        }
      })
    },
    validateStep2(fromUpload = false) {
      try {
        if (this.$refs.form1.image1.value === '' || this.$refs.form2.image1.value === '' || this.$refs.form3.image1.value === '') return false
        this.showModal()
        return this.uploadImage1()
          .then(() => {
            this.uploadImage2()
              .then(async () => {
                await this.uploadImage3()
                if (this.uploadImg1State && this.uploadImg2State && this.uploadImg3State) {
                  this.companyVerifing = true
                  this.companyDocumentsLoad = true
                  this.closeModal()
                  this.$router.push({ name: '/' })
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'The data has been sent correctly. The verification of your identity is being processed and may take between 30 to 60 business days.',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 7000,
                  })
                } else if (!fromUpload) {
                  this.closeModal()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'InfoIcon',
                      text: 'Please attach identity documents for verification',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'bottom-center',
                    timeout: 7000,
                  })
                }
                return true
              })
          })
      } catch (error) {
        return false
      }
    },
    img1Success() {
      if (this.uploadImg1State) return

      this.valueProgress += 30
      this.uploadImg1State = true
    },
    img2Success() {
      if (this.uploadImg2State) return

      this.valueProgress += 30
      this.uploadImg2State = true
    },
    img3Success() {
      if (this.uploadImg3State) return

      this.valueProgress += 30
      this.uploadImg3State = true
    },
    toHome() {
      // this.$sessionData.getAndSet()
      this.$router.push({ name: '/' }).catch(() => {})
    },
  },
}
</script>

<style lang="scss">
  .modal-header .close {
    display:none;
  }
</style>
