<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-left">
          <b-card-title>Create Your ERC20 Token</b-card-title>
          <b-card-text>
            Launch your custom token and use it for exchanges within the Bancannabis ecosystem.
            <br>
            Follow these steps:
            <br><br>
            <p><b>1. Ensure your wallet has the following balances:</b><br>
              <b>a.</b> Token BCA - Required for pairing with your token.<br>
              <b>b.</b> BNB - Needed to cover transaction fees on BEP20.
            </p>
            <p><b>2. Blockchain:</b> Binance Smart Chain.</p>
            <p>
              To top up your balance, go to the Wallet section and
              <b-link :to="{name:'wallet', params: { iz: false}}">
                <span :style="{'color':false?'#75c469':''}">Make a Deposit.</span>
              </b-link>
              Use the QR code to deposit both tokens. If you only have BNB, use Bancannabis' P2P market to exchange for BCA.
            </p>
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="cursor:pointer; float:right"
            @click="deploy">
            <feather-icon icon="PlusIcon" size="16" /> Create Token
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle, BButton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: [
    'deployevent',
  ],
  data() {
    return {
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
      blockchains: [
        // {
        //   title: 'GANACHE Mainnet',
        //   icon: 'BoxIcon',
        //   id: 5777,
        //   name: 'GANACHE',
        //   provider: 'HTTP://127.0.0.1:7545',
        //   scanner: '',
        //   symbol: 'BNB',
        // },
        {
          title: 'Binance (BSC) Mainnet',
          icon: 'BoxIcon',
          id: 0x38,
          name: 'Binance Coin',
          provider: 'https://bsc-dataseed.binance.org/',
          scanner: 'https://bscscan.com',
          symbol: 'BNB',
        },
        // {
        //   title: 'Binance Smart Chain (TESTNET)',
        //   icon: 'BoxIcon',
        //   id: 0x61,
        //   name: 'Binance Coin',
        //   provider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'CELO Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0xA4EC,
        //   name: 'CELO',
        //   provider: 'https://celo-mainnet.infura.io',
        //   scanner: 'https://celoscan.io',
        //   symbol: 'CELO',
        // },
      ],
    }
  },
  methods: {
    deploy() {
      this.$props.deployevent(this.blockchainId.id)
    },
  },
}
</script>
