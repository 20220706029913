<template>
  <div>
    <div
      ref="chartdiv"
      class="hello"
    />
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

export default {
  props: [
    'greenhouseid',
    'tokenaddress',
    'pairname',
    'setpairvalues',
    'wallet',
  ],
  data() {
    return {
      chartData: [],
    }
  },
  created() {
    // debugger
    this.init()
  },
  methods: {
    async init() {
      // eslint-disable-next-line eqeqeq
      // debugger
      if (this.$props.greenhouseid && this.$props.greenhouseid !== 0) {
        this.getData().then(data => {
          this.chartData = data
          // debugger
          setTimeout(() => {
            const root = am5.Root.new(this.$refs.chartdiv)
            root.setThemes([am5themes_Animated.new(root)])
            const chart = root.container.children.push(
              am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout,
              }),
            )

            // const yAxis = chart.yAxes.push(
            //   am5xy.ValueAxis.new(root, {
            //     renderer: am5xy.AxisRendererY.new(root, {
            //       strokeOpacity: 0.1, // Líneas de fondo más suaves
            //       opposite: true,
            //     }),
            //     tooltip: am5.Tooltip.new(root, {}),
            //     // Formato del número para tener decimales
            //     numberFormatter: am5.NumberFormatter.new(root, {
            //       numberFormat: '#.##', // Dos decimales
            //     }),
            //     // Configuración de step: aquí se configura el paso con valores más pequeños
            //     step: 0.5, // Intervalo en 0.1 para mostrar más precisión en los valores
            //     // strictMinMax: true, // Asegurarse de que el rango se respete
            //   }),
            // )

            const yAxis = chart.yAxes.push(
              am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                  strokeOpacity: 0.1,
                  opposite: true,
                }),
                tooltip: am5.Tooltip.new(root, {}),
                numberFormatter: am5.NumberFormatter.new(root, {
                  numberFormat: '#.##', // Dos decimales
                }),
                strictMinMax: true, // Forzar que el rango de valores se respete
              }),
            )

            if (this.chartData.length > 0) {
              const values = this.chartData.map(d => d.close) // Extraer los valores de cierre
              const min = Math.min(...values)
              const max = Math.max(...values)

              yAxis.set('min', min * 0.95) // Ajusta un poco el mínimo
              yAxis.set('max', max * 1.05) // Ajusta un poco el máximo
            }

            // yAxis.set('extraMin', 0.1) // Añadir un pequeño espacio por debajo
            // yAxis.set('extraMax', 0.1) // Añadir un pequeño espacio por encima

            const xAxis = chart.xAxes.push(
              am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {
                  minGridDistance: 50, // Aumentar distancia entre etiquetas
                  strokeOpacity: 0.1, // Líneas de fondo más suaves
                }),
                categoryField: 'category',
              }),
            )
            xAxis.data.setAll(this.chartData)
            // Ocultar las etiquetas en el eje X
            xAxis.get('renderer').labels.template.setAll({
              forceHidden: true,
            })

            const series1 = chart.series.push(
              am5xy.CandlestickSeries.new(root, {
                name: this.$props.pairname,
                xAxis,
                yAxis,
                openValueYField: 'open',
                valueYField: 'close',
                highValueYField: 'high',
                lowValueYField: 'low',
                categoryXField: 'category',
                clustered: true, // Evitar espacio entre velas
              }),
            )
            series1.data.setAll(this.chartData)

            // series1.set('tooltip', am5.Tooltip.new(root, {
            //   labelText: 'Date: {categoryX}\nOpen: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}',
            // }))

            series1.set('tooltip', am5.Tooltip.new(root, {
              labelText: 'Date: {categoryX}\nOpen: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}',
              numberFormatter: am5.NumberFormatter.new(root, {
                numberFormat: '#.#######', // Hasta 6 decimales
              }),
            }))

            chart.set('cursor', am5xy.XYCursor.new(root, {
              behavior: 'none', // Desactivar clics para que solo funcione el hover
              xAxis,
            }))

            this.root = root

            // setTimeout(() => {
            //   document.querySelectorAll('.am5-layer-30').forEach(element => {
            //     // eslint-disable-next-line no-param-reassign
            //     element.style.display = 'none'
            //   })
            // }, 2000)
          }, 1000)
        })
      } else {
        setTimeout(() => {
          this.init()
        }, 1000)
      }
      return true
    },
    getData() {
      return new Promise(resolve => {
        this.$http.get(`/greenhouse/${this.$props.greenhouseid}/bcaPairToken?nid=${this.$sessionData.getCurrentNetworkData().id}&tokenAddressA=${this.$props.tokenaddress}`)
          .then(async _result => {
            // calcular LPs
            const lpFromTokenA = (1 / _result.data.reserveXToken) * _result.data.totalSupply
            const lpFromTokenB = (_result.data.proportionXToBCA / _result.data.reserveBCA) / _result.data.reserveXToken
            const lpTokenAddLiquidity = Math.min(lpFromTokenA, lpFromTokenB)
            // eslint-disable-next-line no-var
            var LPBalance = 0
            // Recuperar LP tokens
            await this.$http.get(`/greenhouse/${this.$props.greenhouseid}/lpbalance?nid=${this.$sessionData.getCurrentNetworkData().id}&wallet=${this.$props.wallet}`)
              .then(resultBalance => {
                LPBalance = resultBalance.data.balance
              })

            // debugger
            this.$props.setpairvalues({
              totalSupply: _result.data.totalSupply, // Total LP
              decimals: 18, // totalSupply.decimals,
              proportionXToBCA: _result.data.proportionXToBCA,
              currentProportionXToBCA: _result.data.proportionBCAToX,
              reserveBCA: _result.data.reserveBCA,
              reserveXToken: _result.data.reserveXToken,
              lpTokenAddLiquidity,
              sincData: this.getData,
              greenHouseId: this.$props.greenhouseid,
              LPBalance,
            })
            resolve((_result.data.chartData !== '') ? _result.data.chartData : [])
          }).catch(() => {
            resolve(false)
          })
      })
    },
  },
}
</script>
<style scoped>
.hello {
  width: 100%;
  height: 180px;
}
</style>
