<template>
  <div>
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->
        <b-col md="12">
          <b-card class="text-left">
            <b-tabs
              pills
            >
              <b-tab
                title="Company"
                active
              >
                <form-wizard-vertical :companystange="companyStange" />
              </b-tab>
              <b-tab
                title="Store Manager"
              >
                <form-wizard
                  ref="wizardCompany"
                  color="#7367F0"
                  :title="null"
                  :subtitle="null"
                  layout="horizontal"
                  finish-button-text="Create"
                  :hide-buttons="true"
                  class="wizard-vertical mb-3"
                >
                  <tab-content
                    title="Build a Store"
                    :before-change="validateStep1"
                  >
                    <b-row>
                      <b-col md="12">
                        <!-- Listado de tiendas -->
                        <fabric-store-list
                          v-show="greenHouseList.length>0"
                          :greenhouseid="greenHouseId"
                          :isload="dataLoaded"
                          :token="userData.token"
                          :blockchainid="blockchainId.id"
                          :activewizard="onActiveWizard"
                          :wallet="userData.data.invitation.wallet"
                          :greenhouselist="greenHouseList"
                        />
                      </b-col>
                      <!-- Si no ha creado una tienda -->
                      <b-col
                        v-show="greenHouseList.length==0"
                        cols="12"
                        class="mb-2"
                      >
                        <select-fabric-card
                          :deployevent="validateStep1"
                        />
                      </b-col>
                    </b-row>
                  </tab-content>
                  <!-- Agregar productos -->
                  <tab-content
                    title="Configure Store"
                    :before-change="validateStep2"
                  >
                    <b-row>
                      <b-col md="12">
                        <validation-observer ref="fabricFormValidation2">
                          <b-form>
                            <!-- <b-row>
                              <b-col md="2"></b-col>
                              <b-col md="8">
                                <b-form-group>
                                  <label>Store Name *</label>
                                  <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="collectionName"
                                  >
                                    <b-form-input
                                      v-model="collectionName"
                                      :state="errors.length > 0 ? false:null"
                                      placeholder="Type your name cannabis company market place..."
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="2"></b-col>
                            </b-row>
                            <b-row>
                              <b-col md="2"></b-col>
                              <b-col md="8">
                                <b-form-group>
                                  <label>Symbol *</label>
                                  <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="Symbol"
                                  >
                                    <b-form-input
                                      v-model="symbol"
                                      :state="errors.length > 0 ? false:null"
                                      placeholder="Exp: BCAM"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="2"></b-col>
                            </b-row> -->
                            <!-- <b-row>
                              <b-col md="2" />
                              <b-col md="8">
                                <b-form-group>
                                  <img
                                    width="35%"
                                    :src="filterCompany.length>0?filterCompany[0].image:''"
                                  >
                                </b-form-group>
                              </b-col>
                              <b-col md="2" />
                            </b-row> -->
                            <!-- <b-row>
                              <b-col md="2" />
                              <b-col md="8">
                                <b-form-group>
                                  <label>Bancannabis Store Contract</label>
                                  <b-form-input
                                    v-model="currentAddressCollection"
                                    placeholder=""
                                    disabled="disabled"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col md="2" />
                            </b-row> -->
                          </b-form>
                        </validation-observer>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <!-- Tienda - productos - pendientes -->
                        <fabric-product-list
                          :greenhouseid="greenHouseId"
                          :isload="dataLoaded"
                          :token="userData.token"
                          :blockchainid="blockchainId.id"
                          :addressstore="currentAddressCollection"
                          :greenhousename="name"
                          :imglogo="urlLogo"
                        />
                      </b-col>
                    </b-row>
                    <!-- <b-button
                      variant="primary"
                      style="cursor:pointer;"
                      @click="back"
                    >
                      Back
                    </b-button> -->
                  </tab-content>
                  <b-modal
                    ref="modalLoading"
                    centered
                    ok-only
                    hide-footer
                    ok-disabled
                    ok-title="Accept"
                    no-close-on-backdrop
                    title="Getting and deploy..."
                  >
                    <b-col>
                      <b-progress
                        animated
                        centered
                        :value="valueProgress"
                        variant="success"
                        class="'progress-bar-success'"
                      />
                      <span>Deploying, please don't close this window...</span>
                    </b-col>
                  </b-modal>
                </form-wizard>
              </b-tab>
              <b-tab
                title="Token Lab"
              >
                <form-wizard
                  ref="wizardToken"
                  color="#7367F0"
                  :title="null"
                  :subtitle="null"
                  layout="horizontal"
                  finish-button-text="Create Token"
                  :hide-buttons="true"
                  class="wizard-vertical mb-3"
                >
                  <tab-content
                    title="Create your own digital token ERC20"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <select-token-fabric-card
                          v-show="storeCreated && !tokenAddress && greenHouseId && greenHouseId != 0"
                          :deployevent="validateStepToken1"
                        />
                        <edit-token-fabric
                          v-show="tokenAddress !== '' && greenHouseId !== 0"
                          :tokenprop="tokenAddress"
                          :eventcreatepair="createPair2Step"
                          :eventexcecexclude="excludeBcaOnPair"
                          :isbcapaircreated="isBcaPairCreated"
                          :chartdata="chartData"
                          :greenhouseid="greenHouseId"
                          :totallptoken="totalLPToken"
                          :eventcreatesupply="createSupply"
                          :eventupdatesupply="updateSupplyXToken"
                          :token="userData.token"
                          :wallet="userData.data.invitation.wallet"
                        />
                        <span v-show="!storeCreated">
                          For use this feature please create an Digital B2B Store
                        </span>
                      </b-col>
                    </b-row>
                  </tab-content>
                  <b-modal
                    ref="modalLoadingToken1"
                    centered
                    ok-only
                    hide-footer
                    ok-disabled
                    ok-title="Accept"
                    no-close-on-backdrop
                    title="Getting and deploy token, please wait..."
                  >
                    <b-col>
                      <b-progress
                        animated
                        centered
                        :value="valueProgress"
                        variant="success"
                        class="'progress-bar-success'"
                      />
                      <span>Deploying, please don't close this window...</span>
                    </b-col>
                  </b-modal>
                  <b-modal
                    ref="modalLoadingToken"
                    centered
                    hide-footer
                    ok-disabled
                    ok-only
                    ok-title="Create Now"
                    no-close-on-backdrop
                    title="Please Configure your token"
                  >
                    <b-row>
                      <b-col md="12">
                        <validation-observer ref="formTokenValidation">
                          <b-form>
                            <b-form-group>
                              <label>Token Name *</label>
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="Token Name"
                              >
                                <b-form-input
                                  ref="tokenNameRef"
                                  v-model="tokenName"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Type your name cannabis Token..."
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                              <br>
                              <label>Decimals *</label>
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="Token Decimals"
                              >
                                <b-form-input
                                  v-model="tokenDecimals"
                                  readonly
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Token Decimals (18 default)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                              <br>
                              <label>Symbol *</label>
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="Token Symbol"
                              >
                                <b-form-input
                                  v-model="tokenSymbol"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Token Symbol (Ej. BCA)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>

                            </b-form-group>
                          </b-form>
                        </validation-observer>
                        <b-button
                          variant="secundary"
                          style="cursor:pointer; float:left"
                          @click="closeTokenModal"
                        >
                          Cancel
                        </b-button>
                        <b-button
                          variant="primary"
                          style="cursor:pointer; float:right"
                          @click="deployToken"
                        >
                          Create
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-modal>
                  <b-modal
                    ref="modalCreatePairLoading"
                    centered
                    ok-only
                    hide-footer
                    ok-disabled
                    ok-title="Accept"
                    no-close-on-backdrop
                    title="Deploying liquidity pool, please wait..."
                  >
                    <b-col>
                      <b-progress
                        animated
                        centered
                        :value="valueProgress"
                        variant="success"
                        class="'progress-bar-success'"
                      />
                      <span>Deploying, please don't close this window...</span>
                    </b-col>
                  </b-modal>
                  <b-modal
                    ref="modalLoadingAddSupply"
                    centered
                    ok-only
                    hide-footer
                    ok-disabled
                    ok-title="Accept"
                    no-close-on-backdrop
                    title="Adding supply to token, please wait..."
                  >
                    <b-col>
                      <b-progress
                        animated
                        centered
                        :value="valueProgressSupply"
                        variant="success"
                        class="'progress-bar-success'"
                      />
                      <span>Adding supply, please don't close this window...</span>
                    </b-col>
                  </b-modal>
                </form-wizard>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  getCurrentInstance,
} from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'
// import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BProgress,
  BModal,
  VBModal,
  BButton,
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectFabricCard from '../../cards/CardSelectFabric.vue'
import SelectTokenFabricCard from '../../cards/CardSelectTokenFabric.vue'
import EditTokenFabric from '../../cards/CardEditTokenFabric.vue'

import FabricProductList from './FabricProductList.vue'
import FabricStoreList from './FabricStoreList.vue'
import states from '../../../assets/json/states.json'
import countries from '../../../assets/json/countries.json'
import cities from '../../../assets/json/cities.json'
import FormWizardVertical from '../company/CompanyForm.vue'

export default {
  components: {
    FormWizard,
    FormWizardVertical,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // vSelect
    BCard,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    SelectFabricCard,
    SelectTokenFabricCard,
    FabricProductList,
    FabricStoreList,
    EditTokenFabric,
    BForm,
    BProgress,
    BModal,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: [
    'companystange',
  ],
  setup() {
    const instance = getCurrentInstance()
    return {
      instance,
    }
  },
  data() {
    return {
      companyStange: 0,
      // variables que manejan el flujo del company
      chartData: [{
        category: '', close: 0, high: 0, low: 0, open: 0,
      }],
      totalLPToken: 0,
      userData: null,
      updateTokenInfo: null,
      img1Selected: false,
      successImg1: false,
      img2Selected: false,
      img1Base64: '',
      successImg2: false,
      disabledImg1: false,
      disabledImg2: false,
      companyVerifing: false,
      companyVerifingOk: false,
      companyDocumentsLoad: false,
      creating: false,
      disabledMail: false,

      // modelo
      name: '',
      description: '',
      usremail: '',
      phone: '',
      address: '',
      address2: '',
      city: '',
      contractType: {
        id: 0,
        text: 'ERC721 (NFT, Exp: products (CBD Oil), services or certifications)',
      },
      company: '',
      country: '',
      estate: '',
      filterStates: [],
      filterContractTypes: [],
      filterCompany: [],
      filterCities: [],
      zipcode: '',
      companyId: null,
      img1Action: '',
      img2Action: '',
      img3Action: '',
      uploadImg1State: false,
      uploadImg2State: false,
      uploadImg3State: false,
      urlLogo: '',
      dataLoaded: false,
      greenHouseId: '',
      collectionName: '',
      symbol: '',
      uriIpfs: '',
      greenHouseList: [],

      tokenName: '',
      tokenDecimals: 18,
      tokenSymbol: '',
      tokenAddress: '',
      storeCreated: false,

      isBcaPairCreated: false,
      currentAddressCollection: '0xC2b56755720Da44C6D911d976fcdDC167F1C454F',

      // Upload image progress
      valueProgress: 10,
      valueProgressSupply: 10,

      // listado para seleccionar
      thestates: states,
      thecountries: countries,
      thecities: cities,
      theContractTypes: [
        {
          id: 0,
          text: 'ERC721 (NFT, Exp: products (CBD Oil), services or certifications)',
        },
        {
          id: 1,
          text: 'Crowgrowing (Soon...)',
        },
        {
          id: 2,
          text: 'ERC20 (Utility Token, Coin Token) (Soon...)',
        },
      ],
      // Validaciones
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
    }
  },
  created() {
    const thatt = this
    async function getCompanyByWallet() {
      return new Promise((resolve, reject) => {
        thatt.$http.get(`/company/${thatt.usremail}/email?nid=${thatt.blockchainId.id}`)
          .then(response => {
            thatt.name = response.data.company.name
            thatt.urlLogo = `https://e-groweed.com:3800/api/v1/image/${response.data.company.sourceDocument3}`
            thatt.company = thatt.name // select imput
            thatt.greenHouseId = response.data.company.greenHouseId
            thatt.filterCompany = [
              {
                id: 0,
                text: thatt.name,
                image: `https://e-groweed.com:3800/api/v1/image/${response.data.company.sourceDocument3}`,
              },
            ]
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    }

    async function getGreenHousesByWallet() {
      return new Promise((resolve, reject) => {
        thatt.$http.get(`/greenhouse?who=${thatt.userData.data.invitation.wallet}`)
          .then(greenHouse => {
            // debugger
            // solo cargar greenhouse con store creada
            if (greenHouse.data.geneticContractAddressDeploied.length > 0) {
              thatt.greenHouseList = [greenHouse.data]
              // debugger
              thatt.storeCreated = greenHouse.data.geneticContractAddressDeploied.length > 0
              thatt.tokenAddress = (greenHouse.data.contractAddressToken === undefined) ? '' : greenHouse.data.contractAddressToken
              thatt.isBcaPairCreated = greenHouse.data.isPairWithBCACreated
            }
            if (thatt.tokenAddress !== 'undefined' && thatt.tokenAddress) {
              // Chart data & informacion del par
              // thatt.$http.get(`/greenhouse/${thatt.greenHouseId}/bcaPairToken?nid=${thatt.$sessionData.getCurrentNetworkData().id}&tokenAddressA=${thatt.tokenAddress}`)
              //   .then(async _result => {
              //     console.log(_result)
              //     debugger
              //     thatt.chartData = (_result.data.chartData !== '') ? _result.data.chartData : []
              //     thatt.totalLPToken = _result.data.totalSupply
              //   })
            }
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    }
    // informacion del usuario
    this.userData = this.$sessionData.get()
    this.usremail = this.userData.data.invitation.email
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
    getCompanyByWallet()
      .then(async result => {
        await getGreenHousesByWallet()
          .then(() => {
            this.dataLoaded = true
            return result
          })
      })
      .catch(() => false)
    this.filterContractTypes = this.theContractTypes
    /*
      if (this.usremail.includes('@yopmail.com')) {
        this.disabledMail = false
        this.usremail = ''
      }
    */
    // variable para manejar el contexto dentro del setTimeOut
    const that = this
    // si ya hay un kyc
    // if (that.userData.kyc !== '' && (that.userData.data.kyc.name !== '' && that.userData.data.kyc.sourceDocument1)) {
    //   this.toHome()
    // }
    // if (that.userData.metamaskSigin) {
    this.disabledMail = true
    // }
    // Verificar el estado del company y el formulario a llenar
    setTimeout(() => {
      // estado 0 = tab1
      if (this.companyId !== null) {
        // tab de adjuntar documentos
        that.$refs.wizardCompany.activateTabAndCheckStep(0)
        // quitar evento volver
        that.$refs.wizardCompany.prevTab = () => {}
        // setear informacion del company
        // that.companyId = that.userData.data.company.id
      }
    }, 500)
  },
  methods: {
    // excecExceclude() {
    //   return true
    // },
    // excludeOfFeeXToken() {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       this.showLoading()
    //       let progressIntervalPair
    //       // guardar fabric
    //       this.valueProgress += 20
    //       // eslint-disable-next-line prefer-const
    //       progressIntervalPair = setInterval(() => {
    //         if (this.valueProgress < 90) {
    //           this.valueProgress += 2.5
    //         }
    //       }, 500)
    //       this.$http.defaults.headers['x-access-token'] = this.userData.token
    //       // debugger
    //       this.$http.post(`/greenhouse/${this.greenHouseId}/erc20exclude?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
    //         wallet: this.userData.data.invitation.wallet,
    //       })
    //         .then(async () => {
    //           this.valueProgress = 100
    //           // this.currentAddressCollection = args[0].data.contract
    //           // this.$refs.wizardToken.finishButtonText = 'finish'
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Notification',
    //               icon: 'InfoIcon',
    //               text: 'Excluded successfull',
    //               variant: 'success',
    //             },
    //           },
    //           {
    //             position: 'bottom-center',
    //             timeout: 5000,
    //           })
    //           this.closeLoading()
    //           clearInterval(progressIntervalPair)
    //           resolve(true)
    //         })
    //         .catch(err => {
    //           this.valueProgress = 0
    //           this.closeLoading()
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: ':(',
    //               icon: 'CloseIcon',
    //               variant: 'warning',
    //               text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
    //             },
    //           })
    //           clearInterval(progressIntervalPair)
    //           reject()
    //         })
    //     } catch (e) {
    //       reject(e)
    //     }
    //   })
    // },
    // excludeBcaOnPair() {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       this.showLoading()
    //       let progressIntervalPair
    //       // guardar fabric
    //       this.valueProgress += 20
    //       // eslint-disable-next-line prefer-const
    //       progressIntervalPair = setInterval(() => {
    //         if (this.valueProgress < 90) {
    //           this.valueProgress += 2.5
    //         }
    //       }, 500)
    //       this.$http.defaults.headers['x-access-token'] = this.userData.token
    //       // debugger
    //       this.$http.post(`/greenhouse/${this.greenHouseId}/bcaexclude?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
    //         wallet: this.userData.data.invitation.wallet,
    //       })
    //         .then(async _result => {
    //           this.valueProgress = 100
    //           // this.currentAddressCollection = args[0].data.contract
    //           // this.$refs.wizardToken.finishButtonText = 'finish'
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Notification',
    //               icon: 'InfoIcon',
    //               text: 'BCA Excluded!. Now you can exchange it on PancakeSwap for BCA',
    //               variant: 'success',
    //             },
    //           },
    //           {
    //             position: 'bottom-center',
    //             timeout: 5000,
    //           })
    //           console.log(_result)
    //           this.isBcaPairCreated = true
    //           this.closeLoading()
    //           clearInterval(progressIntervalPair)
    //           resolve(true)
    //         })
    //         .catch(err => {
    //           this.valueProgress = 0
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: ':(',
    //               icon: 'CloseIcon',
    //               variant: 'warning',
    //               text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
    //             },
    //           })
    //           this.closeCreatePairLoadingModal()
    //           clearInterval(progressIntervalPair)
    //           reject()
    //         })
    //     } catch (e) {
    //       console.log(e)
    //       reject(e)
    //     }
    //   })
    // },
    excludeBcaOnPair() {
      return true
    },
    updateSupplyXToken(updateSupply) {
      this.updateTokenInfo = updateSupply
    },
    back() {
      this.$refs.wizardCompany.activateTabAndCheckStep(0)
    },
    onActiveWizard(deployAddress) {
      this.currentAddressCollection = deployAddress
      this.$refs.wizardCompany.activateTabAndCheckStep(1)
    },
    showModal() {
      this.creating = true
      this.$refs.modalLoading.show()
    },
    closeModal() {
      this.creating = false
      this.$refs.modalLoading.hide()
      // this.toHome()
    },
    showTokenModal() {
      // this.creating = true
      this.$refs.modalLoadingToken.show()
      this.$refs.tokenNameRef.focus()
    },
    closeTokenModal() {
      // this.creating = false
      // this.tokenName = ''
      // this.tokenSymbol = ''
      this.$refs.modalLoadingToken.hide()
      // this.toHome()
    },
    showTokenModalLoading() {
      // this.creating = true
      this.$refs.modalLoadingToken1.show()
    },
    closeCreatePairLoadingModal() {
      this.$refs.modalCreatePairLoading.hide()
    },
    showAddSupplyLoadingModal() {
      // this.creating = true
      this.$refs.modalLoadingAddSupply.show()
    },
    closeAddSupplyLoadingModal() {
      this.$refs.modalLoadingAddSupply.hide()
    },
    showCreatePairLoadingModal() {
      this.$refs.modalCreatePairLoading.show()
    },
    delayOneSec() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 2000)
      })
    },
    closeTokenModalLoading() {
      // this.creating = false
      this.delayOneSec()
        .then(() => {
          this.$refs.modalLoadingToken1.hide()
        })
      // this.toHome()
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    // Cargar combos
    onChangeCountry(e) {
      this.estate = ''
      if (e) this.filterStates = this.thestates.filter(state => state.id_country === e.value)
      else {
        this.filterStates = []
        this.city = ''
        this.estate = ''
        this.filterCities = []
      }
    },
    onChangeState(e) {
      this.city = ''
      if (e) this.filterCities = this.thecities.filter(city => city.id_state === e.value)
      else {
        this.filterCities = []
        this.city = ''
      }
    },
    validateStep1(networkId) {
      return new Promise(resolve => {
        try {
          // debugger
          if (this.userData.metamaskSigin) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: ':(',
                icon: 'CloseIcon',
                variant: 'warning',
                text: 'Sorry, this option is not enabled yet to Metamask Login',
              },
            })
            resolve(false)
            return
          }

          this.$refs.fabricFormValidation2.validate().then(result => {
            if (this.contractType.id !== 0) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: ':(',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: 'Sorry, this option is not enabled yet',
                },
              })
              resolve(false)
              return
            }

            if (!window.confirm('Do you really want to create your digital store now? \nBy accepting, all necessary costs will be automatically debited from your account.')) {
              resolve(false)
              return
            }

            if (result) {
              this.deployMarket(networkId)
                .then(async () => {
                  await this.getGreenHousesByWallet()
                  resolve(true)
                })
                .catch(() => resolve(false))
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'We are sorry :(',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: 'Please create a Company to use this feature.',
                },
              })
              resolve(false)
            }
          })
        } catch (err) {
          resolve(false)
        }
      })
    },
    validateStepToken1() {
      return new Promise(resolve => {
        try {
          if (this.userData.metamaskSigin) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: ':(',
                icon: 'CloseIcon',
                variant: 'warning',
                text: 'Sorry, this option is not enabled yet to Metamask Login',
              },
            })
            resolve(false)
            return
          }

          this.showTokenModal()
        } catch (err) {
          resolve(false)
        }
      })
    },
    validateStep2() {
      this.$refs.fabricFormValidation2.validate().then(result => result)
    },
    createPair2Step(data) {
      return new Promise((resolve, reject) => {
        try {
          this.showCreatePairLoadingModal()
          let progressIntervalPair
          // guardar fabric
          this.valueProgress += 20
          // eslint-disable-next-line prefer-const
          progressIntervalPair = setInterval(() => {
            if (this.valueProgress < 90) {
              this.valueProgress += 2.5
            }
          }, 500)
          this.$http.defaults.headers['x-access-token'] = this.userData.token
          // debugger
          this.$http.post(`/greenhouse/${this.greenHouseId}/bcaPairToken?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
            amountA: data.tokenAAmount,
            amountB: data.tokenBAmount,
            wallet: this.userData.data.invitation.wallet,
          })
            .then(async () => {
              this.valueProgress = 100
              // this.currentAddressCollection = args[0].data.contract
              // this.$refs.wizardToken.finishButtonText = 'finish'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Your Pool token has been successfully created!. Now you can exchange it on PancakeSwap for BCA',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
              // console.log(_result)
              this.isBcaPairCreated = true
              this.closeCreatePairLoadingModal()
              clearInterval(progressIntervalPair)
              resolve(true)
            })
            .catch(err => {
              this.valueProgress = 0
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: ':(',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                },
              })
              this.closeCreatePairLoadingModal()
              clearInterval(progressIntervalPair)
              reject()
            })
        } catch (e) {
          // console.log(e)
          reject(e)
        }
      })
    },
    createSupply(amount) {
      return new Promise((resolve, reject) => {
        try {
          if (amount <= 0) {
            reject()
            return
          }
          this.showAddSupplyLoadingModal()
          let progressIntervalPair
          // guardar fabric
          this.valueProgressSupply += 20
          // eslint-disable-next-line prefer-const
          progressIntervalPair = setInterval(() => {
            if (this.valueProgressSupply < 90) {
              this.valueProgressSupply += 2.5
            }
          }, 500)
          this.$http.defaults.headers['x-access-token'] = this.userData.token
          // debugger
          this.$http.post(`/greenhouse/${this.greenHouseId}/tokensupply?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
            amount,
            wallet: this.userData.data.invitation.wallet,
          })
            .then(async () => {
              this.valueProgressSupply = 100
              // this.currentAddressCollection = args[0].data.contract
              // this.$refs.wizardToken.finishButtonText = 'finish'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'The Supply has been minted!.',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
              // console.log(_result)
              if (this.updateTokenInfo != null) this.updateTokenInfo()
              this.closeAddSupplyLoadingModal()
              clearInterval(progressIntervalPair)
              resolve(true)
            })
            .catch(err => {
              this.valueProgressSupply = 0
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: ':(',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                },
              })
              this.closeAddSupplyLoadingModal()
              clearInterval(progressIntervalPair)
              reject()
            })
        } catch (e) {
          // console.log(e)
          reject(e)
        }
      })
    },
    approveBcaOnStore() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async (resolve, reject) => {
        if (!window.confirm('Do you really want approve your BCA Balance on the Store?.')) {
          reject()
          return
        }
        this.$http.defaults.headers['x-access-token'] = this.userData.token
        let usrBalance = 100 * 10 ** 18 // 100 BCA default
        const ethBalance = await this.$http.get(`/wallet/${this.$sessionData.getUsrWallet()}/bcabalance?nid=${this.$sessionData.getCurrentNetworkData().id}`).catch(() => false)
        if (ethBalance) {
          if (ethBalance.data) usrBalance = ethBalance.data.balance
        }
        const oBalance = usrBalance.split('.')
        let ceros = '000000000000000000'
        // si hay decimales, quitar ceros
        if (oBalance[1]) ceros = ceros.substring(oBalance[1].length, 18)
        const fullBalance = `${usrBalance.replace('.', '')}${ceros}`

        const properties = {
          spender: '0xC2b56755720Da44C6D911d976fcdDC167F1C454F',
          amount: fullBalance,
        }
        this.$http.post(`/wallet/${this.$sessionData.getUsrWallet()}/approvebca?nid=${this.$sessionData.getCurrentNetworkData().id}`, properties)
          .then(async () => {
            this.valueProgress = 100
            this.closeLoading()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Your BCA balance has been successfully approved.',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 15000,
            })
            resolve()
          })
          .catch(() => {
            this.closeLoading()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Could not approved the BCA balance on Store, please try again',
                variant: 'warning',
              },
            },
            {
              position: 'bottom-center',
              timeout: 7000,
            })
            reject()
          })
      })
    },
    allowanceBcaOnStore() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async resolve => {
        this.$http.defaults.headers['x-access-token'] = this.userData.token
        const allowance = await this.$http.get(`/wallet/${this.$sessionData.getUsrWallet()}/bcaallowance?nid=${this.$sessionData.getCurrentNetworkData().id}&spender=0xC2b56755720Da44C6D911d976fcdDC167F1C454F`).catch(() => false)
        resolve(allowance.data)
      })
    },
    deployToken() {
      return new Promise(async (resolve, reject) => {
        try {
          // validar el form
          this.$refs.formTokenValidation.validate().then(result => {
            if (result) {
              // Cargar el modal de cargando y cerrar la modal con el form
              this.showTokenModalLoading()
              this.closeTokenModal()

              let progressIntervalToken
              if (!this.creating) {
                // guardar fabric
                this.valueProgress += 20
                progressIntervalToken = setInterval(() => {
                  if (this.valueProgress < 90) {
                    this.valueProgress += 2.5
                  }
                }, 500)
                this.$http.defaults.headers['x-access-token'] = this.userData.token
                // debugger
                this.$http.post(`/greenhouse/${this.greenHouseId}/deployToken?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
                  name: this.tokenName,
                  symbol: this.tokenSymbol,
                  decimals: this.tokenDecimals,
                  wallet: this.userData.data.invitation.wallet,
                })
                  .then(async _result => {
                    this.valueProgress = 100
                    // this.currentAddressCollection = args[0].data.contract
                    // this.$refs.wizardToken.finishButtonText = 'finish'
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Notification',
                        icon: 'InfoIcon',
                        text: 'Your ERC20 token has been successfully created!',
                        variant: 'success',
                      },
                    },
                    {
                      position: 'bottom-center',
                      timeout: 5000,
                    })
                    // console.log(_result)
                    // debugger
                    this.tokenAddress = _result.data.contract
                    await this.getGreenHousesByWallet()
                    this.instance.proxy.$forceUpdate()
                    this.closeTokenModalLoading()
                    clearInterval(progressIntervalToken)
                    // this.$router.push({ name: '/' })
                    resolve(true)
                  })
                  .catch(err => {
                    this.valueProgress = 0
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: ':(',
                        icon: 'CloseIcon',
                        variant: 'warning',
                        text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                      },
                    })
                    this.tokenName = ''
                    this.tokenSymbol = ''
                    this.closeTokenModalLoading()
                    clearInterval(progressIntervalToken)
                    reject()
                  })
              } else {
                // this.closeModal()
                clearInterval(progressIntervalToken)
                reject()
              }
            }
          })
          resolve()
        } catch (e) {
          // console.log(e)
          reject(e)
        }
      })
    },
    deployMarket() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          // saber si el usuario tiene aprobado BCA en la tienda para poder comprar
          let approve = await this.allowanceBcaOnStore()
          approve = parseFloat(approve)
          if (approve <= 0 || approve <= 2) {
            await this.approveBcaOnStore()
          }
          // resolve(true)
          // return
          // // debugger
          let progressInterval
          // this.$refs.fabricFormValidation2.validate().then(result => {
          if (!this.creating) {
            this.showModal()
            // guardar fabric
            this.valueProgress += 20
            progressInterval = setInterval(() => {
              if (this.valueProgress < 90) {
                this.valueProgress += 2.5
              }
            }, 500)
            this.$http.defaults.headers['x-access-token'] = this.userData.token
            // debugger
            this.$http.post(`/greenhouse/${this.greenHouseId}/deploycollection?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
              name: this.collectionName,
              symbol: this.symbol,
              uri: this.uriIpfs,
              wallet: this.userData.data.invitation.wallet,
            })
              .then(async (...args) => {
                this.valueProgress = 100
                this.currentAddressCollection = args[0].data.contract
                this.$refs.wizardCompany.finishButtonText = 'Save'
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'InfoIcon',
                    text: 'Your asset market has been successfully created. Now you can add items to your market and offer on the platforms',
                    variant: 'success',
                  },
                },
                {
                  position: 'bottom-center',
                  timeout: 15000,
                })
                this.storeCreated = true
                this.closeModal()
                clearInterval(progressInterval)
                // this.$router.push({ name: '/' })
                resolve(true)
              })
              .catch(err => {
                this.valueProgress = 0
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: ':(',
                    icon: 'CloseIcon',
                    variant: 'warning',
                    text: (typeof err.data === 'string') ? err.data : 'Sorry somethings right, try again',
                  },
                })
                this.closeModal()
                clearInterval(progressInterval)
                reject()
              })
          } else {
            // this.closeModal()
            clearInterval(progressInterval)
            reject()
          }
        } catch (error) {
          this.closeModal()
          reject()
        }
      })
    },
    showLoading() {
      this.$refs.modalLoading.show()
    },
    closeLoading() {
      this.$refs.modalLoading.hide()
      // this.toHome()
    },
    getGreenHousesByWallet() {
      return new Promise((resolve, reject) => {
        this.$http.get(`/greenhouse?who=${this.userData.data.invitation.wallet}`)
          .then(greenHouse => {
            // debugger
            // solo cargar greenhouse con store creada
            if (greenHouse.data.geneticContractAddressDeploied.length > 0) {
              this.greenHouseList = [greenHouse.data]
              this.tokenAddress = (greenHouse.data.contractAddressToken === undefined) ? '' : greenHouse.data.contractAddressToken
              this.isBcaPairCreated = greenHouse.data.isPairWithBCACreated
            }
            if (this.tokenAddress !== 'undefined' && this.tokenAddress) {
              // Chart data & informacion del par
              this.$http.get(`/greenhouse/${this.greenHouseId}/bcaPairToken?nid=${this.$sessionData.getCurrentNetworkData().id}&tokenAddressA=${this.tokenAddress}`)
                .then(async _result => {
                  // console.log(_result)
                  // debugger
                  this.chartData = (_result.data.chartData !== '') ? _result.data.chartData : []
                  this.totalLPToken = _result.data.totalSupply
                })
            }
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>

<style lang="scss">
  .modal-header .close {
    display:none;
  }
  .vue-form-wizard {
    .wizard-header {
      display: none;
    }
    ul.wizard-nav-pills,
    .wizard-progress-with-circle {
      display: none !important;
    }
  }
</style>
