<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center card-general">
          <b-card-body>
            <b-tabs
              pills
            >
              <b-tab
                title="Dashboard"
                active
              >
                <b-row>
                  <!-- Columna derecha: Información del token -->
                  <b-col cols="12" md="6" class="text-left mt-2">
                    <b-card-title>{{ name || 'Token Name' }}</b-card-title>
                    <b-card-text>
                      <p><b>Symbol:</b> {{ symbol || 'UKN' }}</p>
                      <p><b>Decimals:</b> {{ decimals || 0 }}</p>
                      <p><b>Address:</b> {{ tokenAux }}</p>
                      <p><b>Total Supply:</b> {{ totalSypply }} {{ symbol }} </p>
                    </b-card-text>
                    <hr>
                    <span>Mint more supply for your token</span>
                    <br><br>
                    <label>{{ symbol }} amount to mint</label>
                    <b-form-input
                      ref="amountToAddSupplyRef"
                      v-model="supplyAddAmount"
                      type="number"
                      placeholder="Type token amount to mint"
                    />
                    <!-- Botones para interactuar con el contrato -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-top: 20px;"
                      @click="createSupply"
                    >
                      <feather-icon icon="PlusIcon" size="16" /> Create Supply
                    </b-button>
                    <!-- <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-top: 20px;"
                      @click="addStoreToMarket"
                    >
                      <feather-icon icon="PlusIcon" size="16" /> Add Token to Store
                    </b-button> -->
                    <!-- <span>Admin Tool</span>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-top: 20px;"
                      @click="excexExclude"
                    >
                      <feather-icon icon="PlusIcon" size="16" /> Exclude
                    </b-button> -->
                    <!-- <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-top: 20px;"
                      @click="bcaExcludeOnPair"
                    >
                      <feather-icon icon="PlusIcon" size="16" /> Exclude
                    </b-button> -->
                  </b-col>
                  <b-col cols="12" md="6" class="mt-2">
                    <p>
                      <span v-show="!logo">Upload your token logo</span>
                      <span v-show="logo">Token Logo</span>
                      <br><br>
                      <b-card-img
                        v-show="logoSrc"
                        :src="logoSrc"
                        alt="Token Logo"
                        style="width: 60px; height: 60px; margin-bottom: 15px;"
                      /><br>
                      <label style="top: 15px; position: relative;" v-show="!logo">Please select a logo for your Token 60x60 px</label><br><br>
                      <b-form-file
                        v-show="!logo"
                        ref="file-erc20logo"
                        v-model="selectedLogo"
                        accept="image/*"
                        @change="previewLogo"
                      >
                        <template v-slot:file-name="{ name }">{{ name || 'Attach Logo' }}</template>
                      </b-form-file>
                    </p>
                    <b-button
                      v-show="!logo"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-top: 20px;"
                      @click="submitLogo"
                    >
                      <feather-icon icon="PlusIcon" size="16" /> Submit Logo
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                title="Pair Liquidity"
              >
                <b-row>
                  <!-- Configuración para crear pool -->
                  <b-col cols="12" md="12" v-show="!$props.isbcapaircreated" class="mb-3">
                    <b-card-title>Launch <b>{{ symbol }}/BCA</b> Pair Pool</b-card-title>
                    <b-card-text>
                      <p>Create a liquidity pool for your community and B2B partners</p>
                      <p>To set up the pool, ensure you deposit the required amount of BCA tokens to balance the equivalent value of both exchange assets.</p>
                    </b-card-text>

                    <!-- Botones para interactuar con el contrato -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-top: 20px;"
                      @click="createPair"
                    >
                      <feather-icon icon="PlusIcon" size="16" />Create Liquid Pool
                    </b-button>
                  </b-col>

                  <!-- Información del pool creado -->
                  <b-col cols="12" md="12" v-show="$props.isbcapaircreated && $props.chartdata.length > 0" class="mb-3">
                    <b-card-title><b>{{ symbol }}/BCA</b></b-card-title>
                    <div>
                      <amchart-pair-bca
                        :tokenaddress="tokenAddress"
                        :greenhouseid="greenHouseId"
                        :pairname="symbol + '/BCA'"
                        :setpairvalues="setPairValues"
                        :wallet="$sessionData.getUsrWallet()"
                      />
                    </div>
                    <br>
                    <b-card-text>
                      <p>
                        <!-- Total {{ symbol }}: <b>{{ $props.chartdata[$props.chartdata.length-1].reserve1 }}</b>
                        <br>
                        Total BCA: <b>{{ $props.chartdata[$props.chartdata.length-1].reserve0 }}</b> -->
                        <!-- <br>
                        Total LP Created: <b>{{ $props.totallptoken }}</b> -->
                        <!-- <br><br>
                        1 <b>{{ symbol }} = {{ $props.chartdata[$props.chartdata.length-1].priceToken1 }} BCA</b>
                        <br>
                        1 <b>BCA = {{ $props.chartdata[$props.chartdata.length-1].priceToken0 }} {{ symbol }}</b> -->
                      </p>
                      <p>You can add or remove liquidity using the Bancannabis Tools.</p>
                    </b-card-text>

                    <!-- Botones para interactuar -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      disabled
                      style="margin-top: 20px;"
                      @click="addLiquidity"
                    >
                      <feather-icon icon="PlusCircleIcon" size="16" /> Add Liquidity
                    </b-button> &nbsp;
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      disabled
                      style="margin-top: 20px;"
                      @click="removeLiquidity"
                    >
                      <feather-icon icon="MinusIcon" size="16" /> Remove Liquidity
                    </b-button>
                    <br><br>
                    <!-- Enlaces -->
                    <a
                      :href="`https://pancakeswap.finance/add/${tokenAux}/0x4C6085438ed6D1aF4930644c370e90DaB811Be90`"
                      target="_blank"
                      class="btn btn-link"
                    >
                      View on PancakeSwap
                    </a>
                    <!-- <a
                      :href="`https://bscscan.com/token/`"
                      target="_blank"
                      class="btn btn-link"
                    >
                      View LP Contract on BscScan
                    </a> -->
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="modalCreatePair"
      centered
      hide-footer
      ok-disabled
      ok-only
      ok-title="Create Pair Swap"
      no-close-on-backdrop
      title="Please Configure your pair deployment"
    >
      <b-row>
        <b-col md="12">
          <p>
            Creating a liquidity pool on PancakeSwap allows your token to be traded easily while earning fees on swaps. Pairing your token with BCA can boost its exposure and adoption on Binance Smart Chain and others chains, making it more accessible.
          </p>
          <p>
            <b>Quick Tip</b>: Estimate the token amounts carefully based on their current price to ensure a balanced and effective pool.
          </p>
          <validation-observer ref="formCreatePairValidation">
            <b-form>
              <b-form-group>
                <label>{{ symbol }} amount *</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Your token amount"
                >
                  <b-form-input
                    ref="tokenAAmountRef"
                    v-model="tokenAAmount"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Type token amount to pairing"
                    @blur="onAmountBlur"
                    @keyup="onAmountBlur"
                    @input="validateIntegerInput"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <label>BCA Amount *</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Token Decimals"
                >
                  <b-form-input
                    v-model="tokenBAmount"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Token vs amount"
                    @blur="onAmountBlur"
                    @keyup="onAmountBlur"
                    @input="validateIntegerInput"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <br>
                <span>The initial price is <b>1 {{ symbol }} = {{ poolBcaPriceEachToken }} BCA</b></span>
              </b-form-group>
            </b-form>
          </validation-observer>
          <b-button
            variant="secundary"
            style="cursor:pointer; float:left"
            @click="closeCreatePairModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            style="cursor:pointer; float:right"
            @click="createPair2Step"
          >
            Create
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      ref="modalLoadingAddLogo"
      centered
      ok-only
      hide-footer
      ok-disabled
      ok-title="Accept"
      no-close-on-backdrop
      title="Adding image logo on your token, please wait..."
    >
      <b-col>
        <b-progress
          animated
          centered
          :value="valueProgressLogo"
          variant="success"
          class="'progress-bar-success'"
        />
        <span>Uploading, please don't close this window...</span>
      </b-col>
    </b-modal>

    <b-modal
      ref="modalConfirmAddSupply"
      centered
      cancel-title="Cancel"
      ok-title="Create Supply"
      ok-variant="success"
      title="Please confirm"
      @ok="confirmAddSupply()"
      @cancel="cancelAddSupply()"
    >
      <b-col>
        <span>Do you want create more supply token?</span>
      </b-col>
    </b-modal>

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle, BCardBody, BButton, BCardImg, BFormFile, BFormGroup, BForm, BModal, BFormInput, VBModal, BTabs, BTab, BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AmchartPairBca from '@core/components/charts/AmChartPairBCA.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AmchartPairBca,
    BProgress,
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BCardImg,
    // BLink,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: [
    'tokenprop',
    'eventcreatepair',
    'eventexcecexclude',
    'isbcapaircreated',
    'chartdata',
    'totallptoken',
    'eventcreatesupply',
    'eventupdatesupply',
    'greenhouseid',
    'token',
    'wallet',
  ],
  data() {
    return {
      chartData: [{
        category: '', close: 0, high: 0, low: 0, open: 0,
      }],
      greenHouseId: 0,
      tokenAddress: '',
      supplyAddAmount: 0,
      chart: null,
      tokenAux: this.$props.tokenprop,
      name: 'Loading...',
      symbol: 'Loading...',
      decimals: 'Loading...',
      totalSypply: 'loading...',
      logo: false,
      logoSrc: '',
      selectedLogo: '',
      poolBcaPriceEachToken: 0,
      valueProgress: 0,
      totalLiquidity: 'Loading...',
      userPoolShare: 'Loading...',
      priceTokenA: 'Loading...',
      priceBCA: 'Loading...',
      excludeAddress: '',
      valueProgressLogo: 0,
    }
  },
  created() {
    // while (!this.$props.tokenprop) {
    //   if (this.$props.tokenprop) {
    //     this.tokenAux = this.$props.tokenprop
    //     this.getTokenInfo()
    //   }
    //   this.delayOneSec()
    // }
    setTimeout(() => {
      this.tokenAux = this.$props.tokenprop
      this.getTokenInfo()
      this.init()
    }, 2000)
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose()
    }
  },
  methods: {
    setPairValues(data) {
      // eslint-disable-next-line no-console
      console.log(data)
    },
    updateSupply() {
      this.$props.eventupdatesupply(this.getTokenInfo)
    },
    init() {
      // debugger
      if (this.$props.greenhouseid) {
        // debugger
        this.greenHouseId = this.$props.greenhouseid
        this.tokenAddress = this.$props.tokenprop
        this.updateSupply()
      } else {
        setTimeout(() => {
          this.init()
        }, 1000)
      }
    },
    secureTokenData() {
      // debugger
      if (this.$props.isbcapaircreated) {
        this.getTokenInfo()
      } else {
        setTimeout(() => {
          this.secureTokenData()
        }, 1000)
      }
    },
    onAmountBlur(e) {
      e.preventDefault()
      if (this.tokenAAmount !== '' && this.tokenAAmount !== 0 && this.tokenBAmount > 0) {
        if (this.tokenBAmount !== '' && this.tokenBAmount !== 0) {
          this.poolBcaPriceEachToken = this.tokenBAmount / this.tokenAAmount
        }
      }
    },
    validateIntegerInput(event) {
      // Elimina caracteres no numéricos o no enteros
      const input = event.target.value
      const validInput = input.replace(/[^0-9]/g, '') // Permite solo números
      // eslint-disable-next-line no-param-reassign
      event.target.value = validInput
      this.tokenBAmount = validInput // Actualiza el modelo solo si el valor es válido
    },
    createPair() {
      this.showCreatePairModal()
    },
    createSupply() {
      this.showConfirmAddSupplyModal()
    },
    // addStoreToMarket() {
    //   this.$http.defaults.headers['x-access-token'] = this.$props.token
    //   // debugger
    //   this.$http.post(`/greenhouse/${this.greenHouseId}/addtokentostore?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
    //     tokenaddress: this.tokenAddress,
    //     wallet: this.$props.wallet,
    //   })
    //     .then(async () => {
    //       alert('success')
    //     })
    // },
    confirmAddSupply() {
      if (this.supplyAddAmount > 0) this.$props.eventcreatesupply(this.supplyAddAmount)
    },
    addLiquidity() {
      return true
    },
    removeLiquidity() {
      return true
    },
    createPair2Step() {
      return new Promise((resolve, reject) => {
        this.$refs.formCreatePairValidation.validate().then(result => {
          if (result) {
            this.closeCreatePairModal()
            // Abrir modal loading
            // hacer llamado al server
            this.$props.eventcreatepair({ tokenAAmount: this.tokenAAmount, tokenBAmount: this.tokenBAmount }, resolve)
          } else {
            reject()
          }
        })
      })
    },
    // excexExclude() {
    //   // eslint-disable-next-line consistent-return
    //   return new Promise(resolve => {
    //     // hacer llamado al server
    //     this.$props.excludeofffeextoken()
    //     resolve(true)
    //   })
    // },
    // bcaExcludeOnPair() {
    //   // eslint-disable-next-line consistent-return
    //   return new Promise(resolve => {
    //     // hacer llamado al server
    //     this.$props.eventexcecexclude()
    //     resolve(true)
    //   })
    // },
    delayOneSec() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 2000)
      })
    },
    getTokenInfo() {
      this.$http.get(`greenhouse/0/erc20?token=${this.tokenAux}&swlogo=true`)
        .then(result => {
          this.name = result.data.name
          this.symbol = result.data.symbol
          this.decimals = result.data.decimals
          // eslint-disable-next-line space-infix-ops
          this.totalSypply = result.data.totalSupply > 0 ? result.data.totalSupply / 10**18 : 0
          this.logoSrc = result.data.imgLogo
          if (this.logoSrc && this.logoSrc !== '') {
            this.logo = true
          }
        })
    },
    previewLogo(event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.logoSrc = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    async submitLogo() {
      // Subir imagen
      if (this.$refs['file-erc20logo'].files.length > 0) {
        this.showAddLogoLoadingModal()
        this.valueProgressLogo += 20
        const progressIntervalAddLogo = setInterval(() => {
          if (this.valueProgressLogo < 90) {
            this.valueProgressLogo += 2.5
          }
        }, 500)
        const img = this.$refs['file-erc20logo'].files[0]
        const form = new FormData()
        form.append('image1', img, img.name)
        const imgUploaded = await this.$http.post('/image', form)
          .catch(() => false)
        if (!imgUploaded) {
          return
        }
        this.logo = true
        this.logoSrc = `https://e-groweed.com:3800/api/v1/image/${imgUploaded.data.imgName}`

        await this.$http.post(`/greenhouse/${this.greenHouseId}/logoToken?nid=${this.$sessionData.getCurrentNetworkData().id}`, {
          imgSrc: this.logoSrc,
        })
        clearInterval(progressIntervalAddLogo)
        this.closeAddLogoLoadingModal()
      }
    },
    showAddLogoLoadingModal() {
      this.$refs.modalLoadingAddLogo.show()
    },
    closeAddLogoLoadingModal() {
      this.$refs.modalLoadingAddLogo.hide()
    },

    showCreatePairModal() {
      this.$refs.modalCreatePair.show()
    },
    closeCreatePairModal() {
      this.$refs.modalCreatePair.hide()
    },

    cancelAddSupply() {
      this.$refs.modalConfirmAddSupply.hide()
    },
    showConfirmAddSupplyModal() {
      // this.creating = true
      this.$refs.modalConfirmAddSupply.show()
    },
  },
}
</script>
<style lang="scss">
  .card-general .card-body {
    padding: 0;
  }
</style>
