<template>
  <div>
    <b-tabs
      pills
      nav-class="mb-3"
      grow
      content-class="mt-3"
    >
      <b-tab
        title="General"
        active
      >
        <p>
          <img
            width="50px"
            :src="this.$props.imglogo"
          >
        </p>
        <p><b>Store Name:</b> {{this.$props.greenhousename}}</p>
        <p>
          You need to activate your store in the Bancannabis Crypto Market. Costs 2 BCA
          <b-button
            variant="light-primary"
            style="border: 1px solid #506344"
            class="m-2"
            @click="showActiveStore()"
          >
            <feather-icon
              icon="CheckIcon"
              size="14"
            /> Active Now
          </b-button>
        </p>
      </b-tab>
      <b-tab
        title="Products"
      >
        <b-card
          title="Product List"
        >
          <b-button
            variant="light-primary"
            style="border: 1px solid #506344"
            class="m-2"
            @click="openProductNewModal()"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
          </b-button>

          <b-list-group v-if="this.$props.isload">
            <template v-for="product in productList">
              <b-list-group-item
                :key="product.name"
                class="d-flex"
              >
                <span class="mr-1">
                  <b-img
                    v-show="product.srcImg !== ''"
                    :src="product.srcImg"
                    fluid
                    width="50px"
                    alt="Product Image"
                  />
                </span>
                <span>
                  {{ product.name }}<br>
                  <span style="font-size: 12px">
                    <b>Inventory</b>: {{ product.inventory }} Units<br>
                    <b>Price</b>: {{ product.price }} BCA<br>
                  </span>
                </span>
                <b-button
                  variant="light-primary"
                  style="position: relative; left: 10%;"
                  @click="openProductEditModal(product.type)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  /> Edit
                </b-button>
              </b-list-group-item>
            </template>
            <!-- Edit product modal -->
            <b-modal
              ref="modalProductEditForm"
              centered
              ok-title="Save"
              no-close-on-backdrop
              title="Update Product"
              @ok="saveProductEdit"
            >
              <b-col>
                <form-wizard
                  ref="wizardProductEdit"
                  color="#7367F0"
                  :title="null"
                  :subtitle="null"
                  layout="horizontal"
                  :hide-buttons="true"
                  class="wizard-vertical mb-3"
                >
                  <validation-observer ref="fabricFormProductEdit">
                    <b-form>
                      <b-row>
                        <b-col md="12">
                          <span class="text-input"> Product Image</span>
                          <br>
                          <b-img
                            v-show="currentProduct[0].srcImg !== ''"
                            :src="currentProduct[0].srcImg"
                            fluid
                            width="200px"
                            alt="Company Image"
                          />
                          <form
                            ref="form2"
                            method="post"
                            target="ifmImage2"
                            enctype="multipart/form-data"
                            style="z-index: 100;"
                          >
                            <div class="file-upload-wrapper">
                              <b-form-file
                                ref="file-input2"
                                class="mb-2"
                                name="image2"
                              />
                            </div>
                            <iframe
                              id="ifmImage2"
                              name="ifmImage2"
                              src=""
                              style="border: 0px; display: none;"
                            />
                          </form>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Name *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productname"
                            >
                              <b-form-input
                                v-model="currentProduct[0].name"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Description *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productdescription"
                            >
                              <b-form-input
                                v-model="currentProduct[0].description"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Descripcion"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Inventory (Cost for modify) *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productinventory"
                            >
                              <b-form-input
                                v-model="currentProduct[0].inventory"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Inventory"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Price (Cost for modify) *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productprice"
                            >
                              <b-form-input
                                v-model="currentProduct[0].price"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Price"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Uses Amount *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productprice"
                            >
                              <b-form-input
                                v-model="currentProduct[0].usableAmount"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Price"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Attributes</h5>
                            <div
                              v-for="(attr, i) in currentAttributes"
                              :key="i"
                            >
                              <label>Attribute {{ i+1 }}</label>
                              <b-form-input
                                v-model="attr.value"
                                placeholder=""
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </form-wizard>
              </b-col>
            </b-modal>
            <!-- new Product modal -->
            <b-modal
              ref="modalProductNewForm"
              centered
              ok-title="Create"
              no-close-on-backdrop
              title="Create new product"
              @ok="saveProductNew"
            >
              <b-col>
                <form-wizard
                  ref="wizardProductNew"
                  color="#7367F0"
                  :title="null"
                  :subtitle="null"
                  layout="horizontal"
                  :hide-buttons="true"
                  class="wizard-vertical mb-3"
                >
                  <validation-observer ref="fabricFormProductNew">
                    <b-form>
                      <b-row>
                        <b-col md="12">
                          <span class="text-input"> Product Image *</span>
                          <b-img
                            v-show="currentProduct[0].srcImg !== ''"
                            :src="currentProduct[0].srcImg"
                            fluid
                            width="200px"
                            alt="Company Image"
                          />
                          <form
                            ref="form1"
                            method="post"
                            target="ifmImage1"
                            enctype="multipart/form-data"
                            style="z-index: 100;"
                          >
                            <div class="file-upload-wrapper">
                              <b-form-file
                                ref="file-input1"
                                class="mb-2"
                                name="image1"
                              />
                            </div>
                            <iframe
                              id="ifmImage1"
                              name="ifmImage1"
                              src=""
                              style="border: 0px; display: none;"
                            />
                          </form>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Name *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productname"
                            >
                              <b-form-input
                                v-model="currentProduct[0].name"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Description *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productdescription"
                            >
                              <b-form-input
                                v-model="currentProduct[0].description"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Descripcion"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Inventory (Cost for modify) *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productinventory"
                            >
                              <b-form-input
                                v-model="currentProduct[0].inventory"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Inventory"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Price (Cost for modify) *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productprice"
                            >
                              <b-form-input
                                v-model="currentProduct[0].price"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Price"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Product Type *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="producttype"
                            >
                              <v-select
                                v-model="currentProduct[0].productType"
                                label="name"
                                :options="productTypes"
                              >
                                <template #option="{ name }">
                                  <!-- <feather-icon
                                    :icon="icon"
                                    size="16"
                                    class="align-middle mr-50"
                                  /> -->
                                  <span> {{ name }}</span>
                                </template>
                              </v-select>
                              <!-- <b-form-input
                                v-model="currentProduct[0].productType"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Price"
                              /> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="currentProduct[0].productType.value > 0">
                        <b-col md="12">
                          <b-form-group>
                            <h5>Uses Amount *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productUses"
                            >
                              <b-form-input
                                v-model="currentProduct[0].usableAmount"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Product Price"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>For delivery *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productDelivery"
                            >
                              <v-select
                                v-model="currentProduct[0].isDelivery"
                                label="name"
                                :options="[{name: 'Si', value: true }, {name: 'No', value: false }]"
                              >
                                <template #option="{ name }">
                                  <span> {{ name }}</span>
                                </template>
                              </v-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Currency *</h5>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="productCurrency"
                            >
                              <v-select
                                v-model="currentProduct[0].currency"
                                label="name"
                                :options="[{name: 'BCA', value: 0, selected: true }, {name: 'Your Token', value: 1 }]"
                              >
                                <template #option="{ name }">
                                  <span> {{ name }}</span>
                                </template>
                              </v-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group>
                            <h5>Attributes</h5>
                            <div
                              v-for="(attr, i) in currentAttributes"
                              :key="i"
                            >
                              <label>Attribute {{ i+1 }}</label>
                              <b-form-input
                                v-model="attr.value"
                                placeholder=""
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </form-wizard>
              </b-col>
            </b-modal>
            <b-modal
              ref="modalLoading"
              centered
              ok-only
              hide-footer
              ok-disabled
              ok-title="Accept"
              no-close-on-backdrop
              title="Saving data..."
            >
              <b-col>
                <b-progress
                  animated
                  centered
                  :value="valueProgress"
                  variant="success"
                  class="'progress-bar-success'"
                />
                <span>Saving data, please wait...</span>
              </b-col>
            </b-modal>
            <b-modal
              ref="modalActiveStore"
              centered
              ok-title="Active now"
              title="Active BCA Store..."
              @ok="activeStore()"
            >
              <b-col>
                <b-progress
                  animated
                  centered
                  :value="valueProgress"
                  variant="success"
                  class="'progress-bar-success'"
                />
                <span>You need to activate the store to be able to use its tools. This activation costs 2 BCA, this value is only canceled once.</span>
              </b-col>
            </b-modal>
          </b-list-group>

        </b-card>
      </b-tab>
      <b-tab
        title="Pendings"
      >
        <b-card
          title="Product List"
        >
          <b-card-text>
            <span>Products pending to mark</span>
          </b-card-text>

          <b-list-group v-if="this.$props.isload">
            <template v-for="product in pendingProductList">
              <b-list-group-item
                :key="product.name"
                class="d-flex"
              >
                <span class="mr-1">
                  <b-img
                    v-show="product.srcImg !== ''"
                    :src="product.srcImg"
                    fluid
                    width="50px"
                    alt="Product Image"
                  />
                </span>
                <span>
                  {{ product.name }}<br>
                  <span style="font-size: 12px">
                    <b>Price</b>: {{ product.price }} {{ product.isNativeToken ? product.tokenSymbol : 'BCA'}}<br>
                  </span>
                </span>
                <b-button
                  variant="light-primary"
                  style="position: relative; left: 10%;"
                  @click="markProdctDelivery(product.tokenId)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  /> Mark Send/Used
                </b-button>
              </b-list-group-item>
            </template>
          </b-list-group>

        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { FormWizard } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BListGroup,
  BListGroupItem,
  BCardText,
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BImg,
  BCol,
  BRow,
  BModal,
  BTabs,
  BTab,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BListGroup,
    BCardText,
    BListGroupItem,
    BButton,
    FormWizard,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormFile,
    BTabs,
    BTab,
    BForm,
    BImg,
    BCol,
    BRow,
    vSelect,
    BModal,
  },
  props: [
    'greenhouseid',
    'isload',
    'token',
    'blockchainid',
    'addressstore',
    'greenhousename',
    'imglogo',
  ],
  data() {
    return {
      required,
      valueProgress: 0,
      pendingProductList: [],
      productList: [
        {
          img: '',
          name: '...',
          inventary: 0,
          soldOut: 0,
          price: 0,
          currency: 0,
          usableAmount: 1,
          productType: { value: 0 },
          isDelivery: false,
          attributes: [{
            trait_type: '...',
            value: '...',
          }],
        },
      ],
      currentProduct: [{
        img: '',
        name: '...',
        inventary: 0,
        soldOut: 0,
        price: 0,
        currency: 0,
        srcImg: '',
        usableAmount: 1,
        productType: { value: 0, name: 'Assign calendar appointment' },
        isDelivery: false,
        attributes: [{
          trait_type: '...',
          value: '...',
        }],
      }],
      productTypes: [
        {
          value: 0,
          name: 'Assign calendar appointment',
        },
        {
          value: 1,
          name: 'Redeemable store product',
        },
      ],
      currentProductInitial: [{
        img: '',
        name: '...',
        inventary: 0,
        soldOut: 0,
        price: 0,
        currency: 0,
        srcImg: '',
        usableAmount: 1,
        productType: { value: 0, name: 'Assign calendar appointment' },
        isDelivery: false,
        attributes: [{
          trait_type: '...',
          value: '...',
        }],
      }],
      currentAttributes: [
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
      ],
      successImg1: false,
    }
  },
  beforeMount() {
    const that = this
    let loaded = false
    let loading = false
    function fetchProducts() {
      // debugger
      if (that.$props.isload) {
        loading = true
        that.$http.get(`/greenhouse/${that.$props.greenhouseid}/genetic`)
          .then(products => {
            that.productList = products.data
            that.$http.get(`/greenhouse/${that.$props.greenhouseid}/pending`)
              .then(pproducts => {
                loaded = true
                loading = false
                that.pendingProductList = pproducts.data
              })
          })
      }
    }
    const loadInterval = setInterval(() => {
      if (!loaded && !loading) {
        fetchProducts()
      } else if (!loading) {
        clearInterval(loadInterval)
      }
    }, 1000)
  },
  methods: {
    openProductEditModal(nftType) {
      // this.$refs.wizardProductEdit.finishButtonText = 'Save'
      this.currentProduct = this.productList.filter(prod => prod.type === nftType)
      delete this.currentProduct.isNativeToken
      this.currentAttributes = this.currentProduct[0].attributes
      this.showModal()
    },
    openProductNewModal() {
      this.showModalNew()
    },
    async activeStore() {
      // validar si el usuario tiene aprobado BCA en la tienda para poder comprar
      await this.approveBcaOnStore()
      if (!window.confirm('Do you really want active the BCA Store?.')) {
        this.showActiveStore()
        return
      }
      this.$http.defaults.headers['x-access-token'] = this.$props.token
      const properties = {
        wallet: this.$sessionData.getUsrWallet(),
      }
      this.$http.post(`/greenhouse/${this.$props.greenhouseid}/active?nid=${this.$props.blockchainid}`, properties)
        .then(async () => {
          this.valueProgress = 100
          this.closeLoading()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'Your store market has been successfully activated.',
              variant: 'success',
            },
          },
          {
            position: 'bottom-center',
            timeout: 15000,
          })
        })
        .catch(() => {
          this.closeLoading()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'Could not active the Store, please try again',
              variant: 'warning',
            },
          },
          {
            position: 'bottom-center',
            timeout: 7000,
          })
        })
    },
    approveBcaOnStore() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async (resolve, reject) => {
        this.$http.defaults.headers['x-access-token'] = this.$props.token
        let usrBalance = 100 * 10 ** 18 // 100 BCA default
        const ethBalance = await this.$http.get(`/wallet/${this.$sessionData.getUsrWallet()}/bcabalance?nid=${this.$props.blockchainid}`).catch(() => false)
        if (ethBalance) {
          if (ethBalance.data) usrBalance = ethBalance.data.balance
        }

        if (usrBalance === 0) {
          reject()
          return
        }

        // saber si el usuario tiene aprobado BCA en la tienda
        const approve = await this.allowanceBcaOnStore()
        const fullBalance = 100000000 * 10 ** 18
        if (approve <= 0) {
          if (!window.confirm('Do you really want to approve the contract to use your BCA tokens in the Store? This is required to allow transactions and purchases using your tokens.')) {
            reject()
            return
          }
          // const oBalance = usrBalance.split('.')
          // let ceros = '000000000000000000'
          // // si hay decimales, quitar ceros
          // if (oBalance[1]) ceros = ceros.substring(oBalance[1].length, 18)
          // const fullBalance = `${usrBalance.replace('.', '')}${ceros}`

          const properties = {
            spender: '0xC2b56755720Da44C6D911d976fcdDC167F1C454F',
            amount: fullBalance,
          }
          this.$http.post(`/wallet/${this.$sessionData.getUsrWallet()}/approvebca?nid=${this.$props.blockchainid}`, properties)
            .then(async () => {
              this.valueProgress = 100
              this.closeLoading()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Your BCA balance has been successfully approved.',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 15000,
              })
              resolve()
            })
            .catch(() => {
              this.closeLoading()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Could not approved the BCA balance on Store, please try again',
                  variant: 'warning',
                },
              },
              {
                position: 'bottom-center',
                timeout: 7000,
              })
              reject()
            })
        } else {
          resolve()
        }
      })
    },
    allowanceBcaOnStore() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async resolve => {
        this.$http.defaults.headers['x-access-token'] = this.$props.token
        const allowance = await this.$http.get(`/wallet/${this.$sessionData.getUsrWallet()}/bcaallowance?nid=${this.$props.blockchainid}&spender=${this.$props.addressstore}`).catch(() => false)
        resolve(allowance.data)
      })
    },
    showModal() {
      this.$refs.modalProductEditForm.show()
    },
    closeModal() {
      this.$refs.modalProductEditForm.hide()
      // this.cleanProduct()
      // this.toHome()
    },
    showModalNew() {
      this.cleanProduct()
      this.$refs.modalProductNewForm.show()
    },
    closeModalNew() {
      this.$refs.modalProductNewForm.hide()
      this.cleanProduct()
      // this.toHome()
    },
    cleanProduct() {
      this.currentAttributes = []
      this.currentProduct = [{
        img: '',
        name: '...',
        inventary: 0,
        soldOut: 0,
        price: 0,
        currency: 0,
        srcImg: '',
        usableAmout: 0,
        productType: { value: 0 },
        attributes: [
          {
            trait_type: '...',
            value: '...',
          },
          {
            trait_type: '...',
            value: '...',
          },
          {
            trait_type: '...',
            value: '...',
          },
        ],
      }]
      this.currentAttributes = [
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
      ]
    },
    showLoading() {
      this.$refs.modalLoading.show()
    },
    closeLoading() {
      this.$refs.modalLoading.hide()
      // this.toHome()
    },
    showActiveStore() {
      this.$refs.modalActiveStore.show()
    },
    closeActiveStore() {
      this.$refs.modalActiveStore.hide()
      // this.toHome()
    },
    cancelFormModal() {
      this.currentProduct = this.currentProductInitial
      this.cleanProduct()
    },
    fetchProducts() {
      if (this.$props.isload) {
        this.$http.get(`/greenhouse/${this.$props.greenhouseid}/genetic`)
          .then(products => {
            this.productList = products.data
            this.$http.get(`/greenhouse/${this.$props.greenhouseid}/pending`)
              .then(pproducts => {
                this.pendingProductList = pproducts.data
              })
          })
      }
    },
    saveProductEdit() {
      this.$refs.fabricFormProductEdit.validate().then(async result => {
        if (!window.confirm('Do you really want update a product data? \nBy accepting, all necessary costs will be automatically debited from your account.')) {
          return
        }

        if (result) {
          this.closeModal()
          this.showLoading()
          const progressInterval = setInterval(() => {
            if (this.valueProgress < 90) {
              this.valueProgress += 2.8
            } else {
              clearInterval(progressInterval)
            }
          }, 500)

          // let srcImgNew = this.currentProduct[0].srcImg
          // // Subir imagen
          // if (this.$refs['file-input2'].files.length > 0) {
          //   const img = this.$refs['file-input2'].files[0]
          //   const form = new FormData()
          //   form.append('image1', img, img.name)

          //   const imgProductData = await this.$http.post('/image', form)
          //     .catch(() => false)
          //   if (!imgProductData) {
          //     return
          //   }
          //   srcImgNew = `https://e-groweed.com:3800/api/v1/image/${imgProductData.data.imgName}`
          // }

          if (this.currentProduct[0].productType === 0) {
            this.currentProduct[0].usableAmount = 1
          } else if (!this.currentProduct[0].usableAmount) {
            this.currentProduct[0].usableAmount = 1
          }
          /* eslint-disable prefer-const */
          let properties = this.currentProduct[0]
          // const properties = {
          //   name: this.currentProduct[0].name,
          //   description: this.currentProduct[0].description,
          //   price: this.currentProduct[0].price,
          //   inventory: this.currentProduct[0].inventory,
          //   usableAmount: this.currentProduct[0].usableAmount,
          //   productType: this.currentProduct[0].productType,
          //   isDelivery: this.currentProduct[0].isDelivery,
          //   type: this.currentProduct[0].type, // bca index
          // }
          // properties.srcImg = srcImgNew
          properties.attributes = this.currentAttributes
          // Subir imagen
          if (this.$refs['file-input2'].files.length > 0) {
            const img = this.$refs['file-input2'].files[0]
            const form = new FormData()
            form.append('image1', img, img.name)
            const imgProductData = await this.$http.post('/image', form)
              .catch(() => false)
            if (!imgProductData) {
              return
            }
            const srcImgLogo = `https://e-groweed.com:3800/api/v1/image/${imgProductData.data.imgName}`
            properties.srcImg = srcImgLogo
          }

          this.$http.defaults.headers['x-access-token'] = this.$props.token
          this.$http.post(`/greenhouse/${this.$props.greenhouseid}/genetic/edit?nid=${this.$props.blockchainid}&wallet=${this.$sessionData.getUsrWallet()}`, properties)
            .then(async () => {
              this.valueProgress = 100
              this.closeLoading()
              this.cleanProduct()
              this.fetchProducts()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Your product market has been successfully updated.',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 15000,
              })
            })
            .catch(() => {
              this.closeLoading()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Could not update the product, please try again',
                  variant: 'warning',
                },
              },
              {
                position: 'bottom-center',
                timeout: 7000,
              })
            })
        }
      })
    },
    saveProductNew() {
      this.$refs.fabricFormProductNew.validate().then(async result => {
        if (!window.confirm('Do you really want create a new product? \nBy accepting, all necessary costs will be automatically debited from your account.')) {
          return
        }
        if (result) {
          this.closeModal()
          this.showLoading()
          const progressInterval = setInterval(() => {
            if (this.valueProgress < 90) {
              this.valueProgress += 2.8
            } else {
              clearInterval(progressInterval)
            }
          }, 500)

          // Subir imagen
          if (this.$refs['file-input1'].files.length <= 0) {
            return
          }
          const img = this.$refs['file-input1'].files[0]
          const form = new FormData()
          form.append('image1', img, img.name)

          const imgProductData = await this.$http.post('/image', form)
            .catch(() => false)
          if (!imgProductData) {
            return
          }
          const srcImgLogo = `https://e-groweed.com:3800/api/v1/image/${imgProductData.data.imgName}`
          // Guardar datos
          this.$http.defaults.headers['x-access-token'] = this.$props.token
          // if (!this.currentProduct[0].productType.value) {
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Notification',
          //       icon: 'InfoIcon',
          //       text: 'Please select an product type',
          //       variant: 'warning',
          //     },
          //   },
          //   {
          //     position: 'bottom-center',
          //     timeout: 7000,
          //   })
          //   return
          // }
          if (this.currentProduct[0].productType.value === 0) {
            this.currentProduct[0].usableAmount = 1
          } else if (!this.currentProduct[0].usableAmount) {
            this.currentProduct[0].usableAmount = 1
          }
          // debugger
          this.$http.post(`/greenhouse/${this.$props.greenhouseid}/genetic?nid=${this.$props.blockchainid}&wallet=${this.$sessionData.getUsrWallet()}`, {
            name: this.currentProduct[0].name,
            description: this.currentProduct[0].description,
            price: this.currentProduct[0].price,
            inventory: this.currentProduct[0].inventory,
            usableAmount: this.currentProduct[0].usableAmount,
            productType: this.currentProduct[0].productType.value,
            isDelivery: this.currentProduct[0].isDelivery.value,
            srcImg: srcImgLogo,
            attributes: this.currentAttributes.filter(attr => attr.value !== '...'),
            isNativeToken: this.currentProduct[0].currency.value === 1,
          })
            .then(async () => {
              this.valueProgress = 100
              this.closeLoading()
              this.cleanProduct()
              this.fetchProducts()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Your product has been successfully created.',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 15000,
              })
            })
            .catch(() => {
              this.closeLoading()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Could not create the product, please try again',
                  variant: 'warning',
                },
              },
              {
                position: 'bottom-center',
                timeout: 7000,
              })
            })
        }
      })
    },
    uploadImage1() {
      return new Promise(resolve => {
        if (this.successImg1) {
          resolve()
          return
        }
        this.valueProgress += 20
        this.$refs.form1.action = 'https://e-groweed.com:3800/api/v1/image'
        this.$refs.form1.submit()
        this.successImg1 = true
        setTimeout(() => {
          this.img1Success() // cambiar estado a enviado
          resolve()
        }, 20000)
      })
    },
    markProdctDelivery(productId) {
      // debugger
      this.$http.defaults.headers['x-access-token'] = this.$props.token
      this.$http.post(`/greenhouse/${this.$props.greenhouseid}/pendingseller?nid=${this.$props.blockchainid}`, {
        tokenId: productId,
        wallet: this.$sessionData.getUsrWallet(),
      })
        .then(async () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'The product has been market state by sent',
              variant: 'success',
            },
          },
          {
            position: 'bottom-center',
            timeout: 15000,
          })
        })
    },
  },
}
</script>
