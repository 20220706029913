<template>
  <b-row>
    <b-col cols="12">
      <form-wizard-vertical :fabricstange="fabricStange" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormWizardVertical from './FabricForm.vue'

export default {
  components: {
    BRow,
    BCol,
    FormWizardVertical,
  },
  data() {
    return {
      fabricStange: 0,
    }
  },
  created() {
    this.getFabricState()
  },
  methods: {
    getFabricState() {
      this.fabricStange = 0
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
