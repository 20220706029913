<template>
  <div>
    <b-tabs
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="CreditCardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Create your company</span>
        </template>
        <company />
      </b-tab>

      <!-- P2p -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="SendIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Crypto Assets</span>
        </template>

        <company-store />
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  // BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Company from '@/views/forms/company/Company.vue'
import CompanyStore from '@/views/forms/fabric/Fabric.vue'

const imgSmall = require('@/assets/images/portrait/small/avatar-s-9.jpg')

export default {
  components: {
    Company,
    CompanyStore,
    // BButton,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    const data = {
      accountSetting: {
        general: {
          avatar: imgSmall,
          username: 'johndoe',
          fullName: 'John Doe',
          email: 'granger007@hogward.com',
          company: 'Crystal Technologies',
        },
        info: {
          bio: '',
          dob: null,
          country: 'USA',
          website: '',
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: 'https://www.linkedin.com',
            instagram: '',
            quora: '',
          },
          connections: {
            twitter: {
              id: 'johndoe',
            },
            google: {
              id: 'luraweber',
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
    }
    return {
      options: data,
      usrName: '',
      usrLastName: '',
      kycState: 0,
    }
  },
  methods: {
    back() {
      const usrData = this.$sessionData.get()
      this.$router.push({ name: '/', params: { iz: usrData.isZero } })
    },
  },
  // beforeCreate() {
  //   // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  // },
}
</script>
