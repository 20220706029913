<template>
  <b-row>
    <b-col cols="12">
      <form-wizard-vertical :companystange="companyStange" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormWizardVertical from './CompanyForm.vue'

export default {
  components: {
    BRow,
    BCol,
    FormWizardVertical,
  },
  data() {
    return {
      companyStange: 0,
    }
  },
  created() {
    this.getCompanyState()
  },
  methods: {
    getCompanyState() {
      this.companyStange = 0
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
