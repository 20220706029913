<template>
  <b-card
    title="Company Store"
  >
    <b-list-group v-if="this.$props.isload">
      {{ $props.greenhouselist.length==0?'No stores found':'' }}
      <template
        v-for="(greenHouse, index) in $props.greenhouselist"
      >
        <b-list-group-item
          :key="index"
          class="d-flex"
        >
          <span class="mr-1">
            <img
              width="90px"
              :src="greenHouse.srcImg"
            >
          </span>
          <span style="width: 50%">
            Store Name: <b>{{ greenHouse.name }}</b>
            <br>
            {{ greenHouse.description?greenHouse.description.substr(0, 50):"" }}...
          </span>
          <b-button
            variant="light-primary"
            style="position: relative; left: 10%;"
            @click="editStore(greenHouse._id)"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
            /> Edit
          </b-button>
        </b-list-group-item>
      </template>
    </b-list-group>

  </b-card>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
} from '@validations'
import {
  BListGroup,
  BListGroupItem,
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  props: [
    'greenhouseid',
    'isload',
    'token',
    'blockchainid',
    'wallet',
    'activewizard',
    'back',
    'greenhouselist',
  ],
  data() {
    return {
      required,
      valueProgress: 0,
      // greenHouseList: [
      //   {
      //     img: '',
      //     name: '...',
      //     inventary: 0,
      //     soldOut: 0,
      //     price: 0,
      //     currency: '...',
      //     attributes: [{
      //       trait_type: '...',
      //       value: '...',
      //     }],
      //   },
      // ],
      currentGreenHouse: [{
        img: '',
        name: '...',
        inventary: 0,
        soldOut: 0,
        price: 0,
        currency: '...',
        attributes: [{
          trait_type: '...',
          value: '...',
        }],
      }],
      currentGreenHouseInitial: [{
        img: '',
        name: '...',
        inventary: 0,
        soldOut: 0,
        price: 0,
        currency: '...',
        attributes: [{
          trait_type: '...',
          value: '...',
        }],
      }],
      currentAttributes: [
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
        {
          trait_type: '...',
          value: '...',
        },
      ],
    }
  },
  beforeMount() {
    // const that = this
    // let loaded = false
    // let loading = false
    // function fetchGreenHouses() {
    //   if (that.$props.isload) {
    //     // debugger
    //     loading = true
    //     if (that.$props.greenhouselist.length) {
    //       if (that.$props.greenhouselist[0].geneticContractAddressDeploied.length > 0) {
    //         that.greenHouseList = that.$props.greenhouselist
    //       }
    //     }
    //     loaded = true
    //     loading = false
    //     // that.$http.get(`/greenhouse?who=${that.$props.wallet}`)
    //     //   .then(greenHouse => {
    //     //     // solo cargar greenhouse con store creada
    //     //     if (greenHouse.data.geneticContractAddressDeploied.length > 0) {
    //     //       that.greenHouseList = greenHouse.data]
    //     //     }
    //     //     loaded = true
    //     //     loading = false
    //     //   })
    //     // .catch(err => {
    //     //   console.log(err)
    //     // })
    //   }
    // }
    // const loadInterval = setInterval(() => {
    //   if (!loaded && !loading) {
    //     fetchGreenHouses()
    //   } else if (!loading) {
    //     clearInterval(loadInterval)
    //   }
    // }, 1000)
  },
  methods: {
    toBack() {
      this.$props.back()
    },
    editStore(greenHouseId) {
      this.$props.greenhouseid = greenHouseId
      const greenHouse = this.$props.greenhouselist.filter(gh => gh.id === greenHouseId)
      this.$props.activewizard(greenHouse[0].geneticContractAddressDeploied[0].contractAddress)
    },
    showModal() {
      this.$refs.modalGreenHouseEditForm.show()
    },
    closeModal() {
      this.$refs.modalGreenHouseEditForm.hide()
      // this.toHome()
    },
    cleanGreenHouse() {
      this.currentAttributes = []
      this.currentGreenHouse = [{
        img: '',
        name: '...',
        inventary: 0,
        soldOut: 0,
        price: 0,
        currency: '...',
        attributes: [{
          trait_type: '...',
          value: '...',
        }],
      }]
    },
    showLoading() {
      this.$refs.modalLoading.show()
    },
    closeLoading() {
      this.$refs.modalLoading.hide()
      // this.toHome()
    },
    cancelFormModal() {
      this.currentGreenHouse = this.currentGreenHouseInitial
    },
    saveGreenHouseEdit() {
      this.$refs.fabricFormGreenHouseEdit.validate().then(result => {
        if (!window.confirm('Do you really want update a Store data? \nBy accepting, all necessary costs will be automatically debited from your account.')) {
          return
        }

        if (result) {
          this.closeModal()
          this.showLoading()
          const progressInterval = setInterval(() => {
            if (this.valueProgress < 90) {
              this.valueProgress += 2.8
            } else {
              clearInterval(progressInterval)
            }
          }, 500)
          this.$http.defaults.headers['x-access-token'] = this.$props.token
          this.$http.post(`/greenhouse/${this.$props.greenhouseid}/genetic/edit?nid=${this.$props.blockchainid}`, {
            name: this.currentGreenHouse[0].name,
            description: this.currentGreenHouse[0].description,
            price: this.currentGreenHouse[0].price,
            inventory: this.currentGreenHouse[0].inventory,
            type: this.currentGreenHouse[0].type,
            attributes: this.currentAttributes,
          })
            .then(async () => {
              this.valueProgress = 100
              this.closeLoading()
              this.cleanGreenHouse()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Your Store market has been successfully updated.',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 15000,
              })
            })
            .catch(() => {
              this.closeLoading()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Could not update the Store, please try again',
                  variant: 'warning',
                },
              },
              {
                position: 'bottom-center',
                timeout: 7000,
              })
            })
        }
      })
    },
  },
}
</script>
